import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import uniqBy from "lodash/uniqBy";
import _isInteger from "lodash/isInteger";
import { filter, nth, sortBy } from "lodash";

//! Antd import
import Row from "antd/lib/row";
import moment from "moment";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import Divider from "antd/lib/divider";
import { Dropdown, Menu, Space } from "antd";

//! User files
import NoContactPlaceholder from "../components/NoContactPlaceholder";
import { AppContext } from "../../../../../AppContext";
import {
  CONTACT_LIMIT,
  DELETE_CONTACT,
  GOOGLE,
  SOURCE_TYPE,
  UPGRADE_PLAN_TITLE,
} from "../../../../../common/constants";
import ContactListHeader from "../components/ContactListHeader";
import ContactList from "../components/ContactList";
import * as ActionTypes from "../../../../../common/actionTypes";
import useDebounce from "../../../../../common/useDebounce";
import ContactCardSkeleton from "../../../../../common/components/ContactCardSkeleton/ContactCardSkeleton";
import ConnectSourcePlaceholder from "../../../../../common/components/ConnectSourcePlaceholder/ConnectSourcePlaceholder";
import {
  getSearchParams,
  handleCsvDownload,
  handleVcfDownload,
  hasGroupRight,
  hasSpaceRight,
  isPlanActive,
  notificationToast,
} from "../../../../../common/utils";
import {
  DustbinIcon,
  Folder,
  MultipleDeleteIcon,
  TagsIcon,
  VerticalMore,
  ExportContact,
} from "../../../../../assets/svg";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";

//! Graphql file
import { GET_COMMON_GROUPS_AND_TAGS } from "../../../graphql/queries/getCommonGroupsAndTags";
import { GET_ALL_CONTACTS } from "../../../graphql/queries/getContactsList";
import { GET_TAGS_LIST } from "../../../graphql/queries/getTagsList";
import { REMOVE_MULTI_CONTACTS } from "../../../graphql/mutations/removeMultiContacts";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";
import { GET_GROUP_LIST_V2 } from "../../../graphql/queries/getGroupListV2";
import { MANAGE_MULTIPLE_GROUPS_AND_TAGS } from "../../../graphql/mutations/manageMultipleGroupsandTags";
import MultipleGroupsModal from "../components/MultipleGroupsModal";
import MultipleTagsModal from "../components/MultipleTagsModal";
import ExportContactModal from "../components/ExportContactModal";
import { GET_GROUPS_FOR_CONTACT } from "../../../graphql/queries/getGroupsForContact";
import api from "../../../../../common/api";

const ContactsList = () => {
  const {
    state: {
      currentSideTab,
      contacts,
      contactsCount,
      isExistingContact,
      unassignedContacts,
      addedContactsCount,
      importStatus,
      userSubscriptionInfo,
      userSpacePermission,
      userGroupPermission,
      currentUser,
    },
    dispatch,
  } = useContext(AppContext);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [removeContact, setRemoveContact] = useState(false);
  const [isTagsContactList, setIsTagsContactList] = useState(false);
  const [myContacts, setMyContacts] = useState();
  const [groupList, setGroupList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteContactIds, setDeleteContactIds] = useState([]);
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
  const [groupListModal, setGroupListModal] = useState(false);
  const [commonGroups, setCommonGroups] = useState([]);
  const [commonTags, setCommonTags] = useState([]);
  const [groupCheckList, setGroupCheckList] = useState([]);
  const [tagCheckList, setTagCheckList] = useState([]);
  const [tagsToUnassign, setTagsToUnassign] = useState([]);
  const [groupsToUnassign, setGroupsToUnassign] = useState([]);
  const [tagOffset, setTagOffset] = useState(0);
  const [groupOffset, setGroupOffset] = useState(0);
  const [totalGroups, setTotalGroups] = useState(null);
  const [totalTags, setTotalTags] = useState(null);
  const [exportContactModal, setExportContactModal] = useState(false);
  const [exportContactFormat, setExportContactFormat] = useState();
  const [exportContactLoader, setexportContactLoader] = useState(false);
  const [tagListModal, setTagListModal] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [groupsToAssign, setGroupsToAssign] = useState([]);
  const [tagsToAssign, setTagsToAssign] = useState([]);
  const [refetchCall, setRefetchCall] = useState(false);
  const location = useLocation();
  const { push } = useHistory();
  const group = location.pathname?.split("/")[3] === "group";
  const tag = location.pathname?.split("/")[3] === "tag";
  const noGroup = location.pathname?.split("/")[4] === "no-groups";
  const tagName = location.pathname?.split("/")[4];
  const groupId =
    location.pathname?.split("/")[4] === "edit"
      ? "all"
      : location.pathname?.split("/")[4] ?? "all";
  const spaceId =
    location.pathname?.split("/")[2] || localStorage.getItem("currentSpace");
  const { search } = useLocation();
  const params = getSearchParams(search);
  const [searchTerm, setSearchTerm] = useState(params || null);

  const debounceSearchVal = useDebounce(searchTerm, 500);
  const checkSpacePermission =
    location.pathname.includes("deleted-contacts") ||
    location.pathname.includes("all") ||
    noGroup ||
    tag;
  const jobId = localStorage.getItem("jobId");
  const { pathname } = useLocation();
  const isDeletedContact = nth(pathname?.split("/"), 4) === "deleted-contacts";
  const contactId = nth(pathname?.split("/"), 6);
  const isContactSelected = nth(pathname?.split("/"), 5) === "contact";

  const [
    getContacts,
    { data: allContacts, loading: contactsLoader },
  ] = useLazyQuery(GET_ALL_CONTACTS, {
    fetchPolicy: "no-cache",
    variables: {
      // eslint-disable-next-line no-nested-ternary
      tag: tag ? (tagName !== "all" ? tagName : null) : null,
      spaceId: spaceId ? parseInt(spaceId, 10) : null,
      // eslint-disable-next-line no-nested-ternary
      groupId: group ? (groupId ? parseInt(groupId, 10) : null) : null,
      data: {
        limit: CONTACT_LIMIT,
        offset: offset,
        sortBy: "ASC",
        sortOn: "firstName",
        query: debounceSearchVal?.toLowerCase().trim() ?? null,
        noGroups: !!location.pathname.includes("no-groups"),
        deleted: !!location.pathname.includes("deleted-contacts"),
      },
    },
    // eslint-disable-next-line no-shadow
    onCompleted({ getContacts }) {
      setMyContacts(getContacts?.data?.contacts);
      if (selectAll) {
        const ids = [];
        getContacts?.data?.contacts?.map((key) =>
          ids.push(parseInt(key?.id, 10))
        );
        setDeleteContactIds([...deleteContactIds, ...ids]);
      }
    },
    onError() {
      setLoading(false);
      push(`/contacts/${spaceId}/group/all`);
    },
  });

  const [fetchTagsList, { loading: tagsLoader }] = useLazyQuery(GET_TAGS_LIST, {
    fetchPolicy: "network-only",
    variables: {
      offset: tagOffset,
      limit: CONTACT_LIMIT,
      spaceId: parseInt(spaceId, 10),
    },
    onCompleted({ tagList }) {
      setTotalTags(tagList?.data?.count - 1);
      setTagsList(
        sortBy(
          uniqBy(
            [
              ...tagsList,
              ...filter(tagList?.data?.tags, (e) => e?.type !== "AUTO"),
            ],
            "tag"
          ),
          "tag"
        )
      );
    },

    onError(error) {
      return error;
    },
  });

  const sourceId =
    // eslint-disable-next-line no-nested-ternary
    group &&
    groupId !== "all" &&
    groupId !== "deleted-contacts" &&
    groupId !== "no-groups"
      ? groupId
      : // eslint-disable-next-line no-nested-ternary
      tag
      ? tagName !== "all"
        ? tagName
        : null
      : null;

  const getSourceType = () => {
    if (selectAll) {
      let sourceValue = null;
      if (group && _isInteger(parseInt(groupId, 10))) {
        sourceValue = SOURCE_TYPE.GROUP_CONTACTS;
      }
      if (tag && tagName !== "all") {
        sourceValue = SOURCE_TYPE.TAG_CONTACTS;
      }
      if (noGroup) {
        sourceValue = SOURCE_TYPE.NO_GROUP_CONTACTS;
      }
      if (location.pathname.includes("deleted-contacts")) {
        sourceValue = SOURCE_TYPE.DELETED_CONTACTS;
      }
      if (groupId === "all") {
        sourceValue = SOURCE_TYPE.ALL_CONTACTS;
      }
      return sourceValue;
    }
    return null;
  };

  const [
    getCommonGroupsTags,
    { loading: commonGroupsTagsLoader },
  ] = useLazyQuery(GET_COMMON_GROUPS_AND_TAGS, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted({ getCommonContactGroupsTags }) {
      setCommonGroups(getCommonContactGroupsTags?.groupIds);
      setCommonTags(getCommonContactGroupsTags?.tags);
    },
    onError(error) {
      return error;
    },
  });
  const [getGroupInfo] = useLazyQuery(GET_GROUP_INFO_V2, {
    fetchPolicy: "network-only",
    onCompleted({ groupInfoV2 }) {
      dispatch({
        type: ActionTypes.SET_USER_GROUP_PERMISSION,
        data: groupInfoV2?.permission,
      });
    },
    onError() {
      push(`/contacts/${spaceId}/group/all`);
    },
  });

  const resetData = () => {
    setDeleteContactIds([]);
    setBulkDeleteModal(false);
    setSelectAll(false);
    setOffset(0);
  };

  const [getGroupsForContacts, { loading: groupListLoader }] = useLazyQuery(
    GET_GROUPS_FOR_CONTACT,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: groupOffset,
        limit: CONTACT_LIMIT,
        spaceId: parseInt(spaceId, 10),
      },
      onCompleted({ getGroupsForContact }) {
        setTotalGroups(getGroupsForContact?.count);
        setGroupList(
          uniqBy([...groupList, ...getGroupsForContact?.groups]),
          "id"
        );
      },
      onError() {},
    }
  );
  const [getGroupListV2] = useLazyQuery(GET_GROUP_LIST_V2, {
    fetchPolicy: "network-only",
    variables: {
      offset: groupOffset,
      limit: CONTACT_LIMIT,
      spaceId: parseInt(spaceId, 10),
    },

    onError() {},
  });

  const resetSelectedItems = () => {
    getGroupListV2();
    fetchTagsList();
    setGroupList([]);
    setTagsList([]);
    setGroupsToAssign([]);
    setGroupsToUnassign([]);
    setTagsToAssign([]);
    setTagsToUnassign([]);
    setTagOffset(0);
    setGroupOffset(0);
    dispatch({
      type: ActionTypes.SET_REFETCH_TAGS,
      data: true,
    });

    if (contactId && isContactSelected) {
      dispatch({
        type: ActionTypes.SET_CONTACT_REFETCH,
        data: true,
      });
    }
  };

  const [
    manageMultipleGroupsTags,
    { loading: manageGroupsTagsLoader },
  ] = useMutation(MANAGE_MULTIPLE_GROUPS_AND_TAGS, {
    variables: {
      data: {
        ids: selectAll ? [] : deleteContactIds,
        spaceId: parseInt(spaceId, 10),
        sourceType: getSourceType(),

        sourceId,
        groupsToAssign: groupsToAssign?.length > 0 ? groupsToAssign : null,
        tagsToAssign: tagsToAssign?.length > 0 ? tagsToAssign : null,
        tagsToUnAssign: tagsToUnassign?.length > 0 ? tagsToUnassign : null,
        groupsToUnAssign:
          groupsToUnassign?.length > 0 ? groupsToUnassign : null,
        search: debounceSearchVal?.toLowerCase().trim() ?? null,
      },
    },
    refetchQueries: [
      {
        query: GET_SPACE_DETAIL_V2,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(spaceId, 10),
        },
      },
      {
        query: GET_TAGS_LIST,
        fetchPolicy: "network-only",
        variables: {
          offset: 0,
          limit: CONTACT_LIMIT,
          spaceId: parseInt(spaceId, 10),
        },
      },
    ],
    onCompleted() {
      setRefetchCall(true);
      resetData();
      resetSelectedItems();
    },
    onError() {
      resetData();
      resetSelectedItems();
    },
  });

  const [multiContactDelete, { loading: multiDeleteLoader }] = useMutation(
    REMOVE_MULTI_CONTACTS,
    {
      refetchQueries: [
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
        {
          query: GET_TAGS_LIST,
          fetchPolicy: "network-only",
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            spaceId: parseInt(spaceId, 10),
          },
        },
      ],
      onCompleted() {
        dispatch({
          type: ActionTypes.SET_CONTACTS,
          data: [],
        });
        dispatch({
          type: ActionTypes.SET_CONTACTS_COUNT,
          data: 0,
        });
        setRefetchCall(true);
        resetData();
        dispatch({
          type: ActionTypes.SET_REFETCH_TAGS,
          data: true,
        });
      },
      onError() {
        resetData();
      },
    }
  );

  useEffect(() => {
    if (location.pathname.includes("tag")) {
      setIsTagsContactList(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setSearchTerm(params || null);
    if (
      (groupId !== "edit" && groupId !== "all" && spaceId !== undefined) ||
      noGroup
    ) {
      setOffset(0);
      setLoading(true);
      dispatch({
        type: ActionTypes.SET_CONTACTS,
        data: [],
      });
      dispatch({
        type: ActionTypes.SET_CONTACTS_COUNT,
        data: 0,
      });
      setDeleteContactIds([]);
      setSelectAll(false);
      setMyContacts();
      getContacts();
      if (
        !tag &&
        groupId !== "all" &&
        groupId !== "new" &&
        groupId !== null &&
        _isInteger(parseInt(groupId, 10))
      ) {
        getGroupInfo({
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(groupId, 10),
          },
        });
      }
    }
    if (spaceId === undefined) {
      setOffset(0);
      setLoading(true);
      dispatch({
        type: ActionTypes.SET_CONTACTS,
        data: [],
      });
      dispatch({
        type: ActionTypes.SET_CONTACTS_COUNT,
        data: 0,
      });
      setMyContacts();
    }
    if (groupId === "all") {
      setDeleteContactIds([]);
      setSelectAll(false);
    }
    return () => {
      setMyContacts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, spaceId, noGroup]);

  useEffect(() => {
    setOffset(0);
    setLoading(true);
    dispatch({
      type: ActionTypes.SET_CONTACTS,
      data: [],
    });
    dispatch({
      type: ActionTypes.SET_CONTACTS_COUNT,
      data: 0,
    });
    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    try {
      if (!contactsLoader) {
        if (allContacts?.getContacts?.data?.contacts) {
          if (offset === 0) {
            dispatch({
              type: ActionTypes.SET_CONTACTS,
              data: uniqBy(allContacts?.getContacts?.data?.contacts, "id"),
            });
            dispatch({
              type: ActionTypes.SET_CONTACTS_COUNT,
              data: allContacts?.getContacts?.data?.count,
            });
          } else {
            dispatch({
              type: ActionTypes.SET_CONTACTS,
              data: uniqBy(
                [...contacts, ...allContacts?.getContacts?.data?.contacts],
                "id"
              ),
            });
            dispatch({
              type: ActionTypes.SET_CONTACTS_COUNT,
              data: allContacts?.getContacts?.data?.count,
            });
          }
        } else {
          dispatch({
            type: ActionTypes.SET_CONTACTS,
            data: [],
          });
          dispatch({
            type: ActionTypes.SET_CONTACTS_COUNT,
            data: 0,
          });
        }
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts, myContacts]);

  useEffect(() => {
    if (debounceSearchVal !== undefined && debounceSearchVal !== "") {
      dispatch({
        type: ActionTypes.SET_CONTACTS,
        data: [],
      });
      dispatch({
        type: ActionTypes.SET_CONTACTS_COUNT,
        data: 0,
      });
      getContacts();
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchVal]);

  useEffect(() => {
    if (isExistingContact) {
      setRemoveContact(true);
    } else {
      setRemoveContact(false);
    }
  }, [isExistingContact]);

  const addMultipleContact = () => {
    dispatch({
      type: ActionTypes.SET_EXISTING_DONE_CLICK,
      data: true,
    });
    dispatch({
      type: ActionTypes.SET_IS_EXISTING_CONTACT,
      data: false,
    });
    push(`/contacts/${spaceId}/group/${groupId}/settings`);
  };

  const handleRouteChange = (nextLocation) => {
    if (
      nextLocation &&
      (unassignedContacts?.length > 0 || addedContactsCount > 0)
    ) {
      // eslint-disable-next-line no-alert
      const value = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (value) {
        dispatch({
          type: ActionTypes.SET_IS_EXISTING_CONTACT,
          data: false,
        });
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: {},
        });
        dispatch({
          type: ActionTypes.SET_ADDED_CONTACTS_COUNT,
          data: 0,
        });
        return true;
      }
      return false;
    }
    dispatch({
      type: ActionTypes.SET_IS_EXISTING_CONTACT,
      data: false,
    });
  };

  useEffect(() => {
    setGroupCheckList([...commonGroups, ...groupCheckList]);
    setTagCheckList([...commonTags, ...tagCheckList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonGroups, commonTags]);
  useEffect(() => {
    if (
      (location.pathname.includes("deleted-contacts") ||
        location.pathname.includes("no-groups")) &&
      allContacts?.getContacts?.data?.count === 0 &&
      (debounceSearchVal?.length === undefined ||
        debounceSearchVal?.length === null)
    ) {
      push(`/contacts/${spaceId}/group/all`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts]);

  const handleSelectAll = (val) => {
    if (val) {
      setSelectAll(val);
      const ids = [];
      contacts?.map((key) => ids.push(parseInt(key?.id, 10)));
      setDeleteContactIds([...ids]);
    } else {
      setDeleteContactIds([]);
    }
  };

  const handleBulkDeleteModal = () => {
    if (!deleteContactIds?.length) {
      return;
    }
    setBulkDeleteModal(!bulkDeleteModal);
  };

  const handleExportContactModal = () => {
    setExportContactModal(!exportContactModal);
  };

  const handleManageExportContact = () => {
    if (exportContactFormat) {
      setexportContactLoader(true);
      const filterExport = {
        userId: currentUser?.id,
        spaceId: spaceId,
      };
      if (groupId !== "all" && groupId !== "no-groups") {
        filterExport.groupId = groupId;
      }
      if (!selectAll && deleteContactIds) {
        filterExport.contactIds = deleteContactIds;
      }
      api
        ?.post(
          `/export/${exportContactFormat === "csv" ? "csv" : "vcf"}`,
          filterExport
        )
        .then(function (response) {
          if (exportContactFormat === "csv") {
            handleCsvDownload(
              response?.data,
              `CONTACTS-EXPORT-CSV-${moment().format("MM-DD-YYYY")}.csv`
            );
          } else {
            handleVcfDownload(
              response?.data,
              `CONTACTS-EXPORT-VCF-${moment().format("MM-DD-YYYY")}.vcf`
            );
          }
          notificationToast({
            message: "Contacts exports successfully!",
            type: "success",
          });
          setExportContactModal(false);
          setRefetchCall(true);
          resetData();
          resetSelectedItems();
          setExportContactFormat();
        })
        .catch(function (error) {
          if (error?.message) {
            notificationToast({
              message: error?.message,
              type: "error",
            });
            setexportContactLoader(false);
          }
        });
    }
  };

  const handleManageTags = () => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      getCommonGroupsTags({
        variables: {
          data: {
            ids: selectAll ? [] : deleteContactIds,
            spaceId: parseInt(spaceId, 10),
            sourceType: getSourceType(),
            sourceId,

            search: debounceSearchVal?.toLowerCase()?.trim() ?? null,
          },
        },
      });

      setTagListModal(true);
    } else {
      dispatch({
        type: ActionTypes.SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    }
  };

  const handleManageGroups = () => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      getCommonGroupsTags({
        variables: {
          data: {
            ids: selectAll ? [] : deleteContactIds,
            spaceId: parseInt(spaceId, 10),
            sourceType: getSourceType(),
            sourceId,
            search: debounceSearchVal?.toLowerCase()?.trim() ?? null,
          },
        },
      });
      setGroupListModal(true);
    } else {
      dispatch({
        type: ActionTypes.SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    }
  };
  const handleBulkDelete = () => {
    if (deleteContactIds?.length > 0) {
      multiContactDelete({
        variables: {
          data: {
            ids: selectAll ? [] : deleteContactIds,
            spaceId: parseInt(spaceId, 10),
            sourceType: getSourceType(),
            sourceId,
            permanent:
              !!location.pathname.includes("deleted-contacts") || false,
            search: debounceSearchVal?.toLowerCase().trim() ?? null,
          },
        },
      })
        .then(() => {
          if (selectAll) {
            getGroupListV2();
          }
          if (groupId > 0 && _isInteger(parseInt(groupId, 10))) {
            getGroupInfo({
              variables: {
                id: parseInt(groupId, 10),
              },
            });
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (deleteContactIds?.length === contacts?.length && contactsCount !== 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteContactIds]);

  useEffect(() => {
    if (refetchCall) {
      getContacts();
    }
    setRefetchCall(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    if (refetchCall) {
      getContacts();
    }
    setRefetchCall(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchCall]);

  const menu2 = (
    <Menu className="cms-menu">
      <Menu.Item
        className="cms-menu-item"
        onClick={tag ? handleManageGroups : handleManageTags}
        icon={
          <span className="cms-menu-item-svg">
            {tag ? (
              <Folder height={16} width={16} />
            ) : (
              <TagsIcon height={16} width={16} />
            )}
          </span>
        }
      >
        {tag ? "Manage Groups" : "Manage Tags"}
      </Menu.Item>
      <>
        <Menu.Divider />
        <Menu.Item
          className="cms-menu-item "
          onClick={handleExportContactModal}
          icon={
            <span className="cms-menu-item-svg">
              <ExportContact height={16} width={16} />
            </span>
          }
        >
          Export
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          className="cms-menu-item cms-delete-text"
          onClick={handleBulkDeleteModal}
          icon={
            <span className="cms-menu-item-svg cms-delete">
              <DustbinIcon height={16} width={16} />
            </span>
          }
        >
          Delete
        </Menu.Item>
      </>
    </Menu>
  );

  return (
    <>
      <Prompt
        when={unassignedContacts?.length > 0 || addedContactsCount > 0}
        message={handleRouteChange}
      />
      <ContactListHeader
        contactCount={allContacts?.getContacts?.data?.count}
        name={currentSideTab ?? localStorage.getItem("currentGroup")}
        group={group}
        tag={tag}
        groupId={parseInt(groupId, 10)}
        setSearchTerm={setSearchTerm}
        removeContact={removeContact}
        addMultipleContact={addMultipleContact}
        spaceId={spaceId}
        isTagsContactList={isTagsContactList}
        tagName={
          tagName === "source-mobile" ? "Mobile Device Contacts" : tagName
        }
        searchTerm={searchTerm}
      />
      {(checkSpacePermission
        ? hasSpaceRight(userSpacePermission, DELETE_CONTACT)
        : hasGroupRight(userGroupPermission, DELETE_CONTACT)) &&
        allContacts?.getContacts?.data?.count > 0 && (
          <Row className="cms-multiple-delete">
            <Col span={10} className="center cms-multiple-delete-select-all">
              <span>
                <Checkbox
                  className="font-inter cms-select-checkbox"
                  onChange={(e) => handleSelectAll(e?.target?.checked)}
                  checked={selectAll}
                >
                  <span className="select-text">Select All</span>
                </Checkbox>
              </span>
            </Col>
            <Divider type="vertical" className="full-height" />
            <>
              {!isDeletedContact ? (
                <>
                  <Col
                    span={10}
                    className="center cms-multiple-delete-section-delete"
                  >
                    <div
                      className={`${
                        deleteContactIds?.length
                          ? "cursor-pointer"
                          : "cursor-not-allowed op-05"
                      } align-center`}
                    >
                      <>
                        {tag ? (
                          <div
                            onClick={
                              deleteContactIds?.length > 0
                                ? handleManageTags
                                : null
                            }
                          >
                            <TagsIcon className="mr-8" />
                            <span className="font-inter">Manage Tags</span>
                          </div>
                        ) : (
                          <div
                            className="d-flex"
                            onClick={
                              deleteContactIds?.length > 0
                                ? handleManageGroups
                                : null
                            }
                          >
                            <Folder className="mr-8" />
                            <span className="font-inter ">Manage Groups</span>
                          </div>
                        )}
                      </>
                    </div>
                  </Col>
                  <Divider
                    type="vertical"
                    className="full-height divider-vertical"
                  />
                  <Dropdown
                    overlay={menu2}
                    trigger={["click"]}
                    className={`m-auto ${
                      deleteContactIds?.length <= 0 && "op-05"
                    }`}
                    disabled={deleteContactIds?.length <= 0}
                  >
                    <Space>
                      <VerticalMore
                        className={`${
                          deleteContactIds?.length <= 0
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                      />
                    </Space>
                  </Dropdown>
                </>
              ) : (
                <Col
                  span={10}
                  className="center cms-multiple-delete-section-delete"
                >
                  <div
                    className={`${
                      deleteContactIds?.length ? "cursor-pointer" : ""
                    } cms-multiple-delete-section-delete-div`}
                    onClick={handleBulkDeleteModal}
                  >
                    <MultipleDeleteIcon className="mr-4" />
                    <span className="font-inter delete-text">Delete</span>
                  </div>
                </Col>
              )}
            </>
          </Row>
        )}
      {
        // eslint-disable-next-line no-nested-ternary
        (contactsLoader && offset === 0) ||
        loading ||
        importStatus === "PENDING" ||
        jobId > 0 ||
        (allContacts?.getContacts?.data?.count === undefined &&
          !contactsLoader) ? (
          <div>
            <ContactCardSkeleton loading active />
          </div>
        ) : // eslint-disable-next-line no-nested-ternary
        contacts?.length > 0 ? (
          <ContactList
            searchTerm={searchTerm}
            group={group}
            tag={tag}
            data={contacts}
            groupId={groupId}
            tagName={tagName}
            setOffset={setOffset}
            contactLoading={contactsLoader}
            groupname={currentSideTab ?? localStorage.getItem("currentGroup")}
            removeContact={removeContact}
            selectAll={selectAll}
            deleteContactIds={deleteContactIds}
            setDeleteContactIds={setDeleteContactIds}
            checkSpacePermission={checkSpacePermission}
          />
        ) : hasSpaceRight(userSpacePermission, "addContact") &&
          userSubscriptionInfo?.type !== GOOGLE &&
          (allContacts?.getContacts?.data?.count === 0 ||
            userSubscriptionInfo?.contactsCount === 0) &&
          groupId === "all" &&
          !contactsLoader &&
          (debounceSearchVal === null ||
            debounceSearchVal === undefined ||
            debounceSearchVal?.length === 0) &&
          importStatus !== "PENDING" ? (
          <ConnectSourcePlaceholder />
        ) : (
          (allContacts?.getContacts?.data?.count === 0 ||
            (location.pathname.includes("add-contacts") &&
              contacts?.length === 0) ||
            ((debounceSearchVal?.length > 0 || debounceSearchVal === null) &&
              contacts?.length === 0)) && (
            <NoContactPlaceholder
              contactsCount={contactsCount}
              groupId={groupId}
              spaceId={spaceId}
              searchTerm={searchTerm}
            />
          )
        )
      }
      <RemovePopup
        visible={bulkDeleteModal}
        handleRemove={handleBulkDelete}
        removeNote={handleBulkDeleteModal}
        closeText="Cancel"
        okText="Delete"
        content={
          <span className="popup-text-title font-inter">
            Are you sure you want to delete all selected contacts?
          </span>
        }
        deleteLoader={multiDeleteLoader}
        disabled={multiDeleteLoader}
        maskClosable={false}
      />
      {groupListModal && (
        <MultipleGroupsModal
          setGroupListModal={setGroupListModal}
          groupListModal={groupListModal}
          getGroupsForContacts={getGroupsForContacts}
          groupList={groupList}
          setGroupList={setGroupList}
          groupListLoader={groupListLoader}
          setGroupOffset={setGroupOffset}
          groupOffset={groupOffset}
          commonGroups={commonGroups}
          manageMultipleGroupsTags={manageMultipleGroupsTags}
          groupCheckList={groupCheckList}
          setGroupCheckList={setGroupCheckList}
          setCommonGroups={setCommonGroups}
          setGroupsToUnassign={setGroupsToUnassign}
          groupsToUnassign={groupsToUnassign}
          totalGroups={totalGroups}
          manageGroupsTagsLoader={manageGroupsTagsLoader}
          groupsToAssign={groupsToAssign}
          setGroupsToAssign={setGroupsToAssign}
          commonGroupsTagsLoader={commonGroupsTagsLoader}
        />
      )}
      {tagListModal && (
        <MultipleTagsModal
          setTagOffset={setTagOffset}
          setTagsList={setTagsList}
          tagOffset={tagOffset}
          tagsLoader={tagsLoader}
          commonTags={commonTags}
          manageMultipleGroupsTags={manageMultipleGroupsTags}
          tagCheckList={tagCheckList}
          setTagCheckList={setTagCheckList}
          setTagListModal={setTagListModal}
          tagListModal={tagListModal}
          fetchTagsList={fetchTagsList}
          tagsList={tagsList}
          setCommonTags={setCommonTags}
          setTagsToUnassign={setTagsToUnassign}
          tagsToUnassign={tagsToUnassign}
          totalTags={totalTags}
          manageGroupsTagsLoader={manageGroupsTagsLoader}
          tagsToAssign={tagsToAssign}
          setTagsToAssign={setTagsToAssign}
          commonGroupsTagsLoader={commonGroupsTagsLoader}
        />
      )}
      {exportContactModal && (
        <ExportContactModal
          setExportContactModal={setExportContactModal}
          exportContactModal={exportContactModal}
          exportContactFormat={exportContactFormat}
          setExportContactFormat={setExportContactFormat}
          exportContactLoader={exportContactLoader}
          handleManageExportContact={handleManageExportContact}
        />
      )}
    </>
  );
};

export default ContactsList;
