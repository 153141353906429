import React from "react";

//! User files
import ShareMembderGIF from "../../../assets/images/share-member.gif";
import {
  SHARE_GOOGLE_CONTACTS_TEXT,
  SHARE_GOOGLE_CONTACTS_URL,
} from "../../constants";

const GoogleSpaceEmptyState = () => {
  return (
    <div className="cms-tag-empty-state">
      <span level={3} className="font-inter cms-tag-empty-state-title">
        Share Contacts with other Google users
      </span>
      <span className="cms-tag-empty-state-image">
        <img src={ShareMembderGIF} alt="google space" />
      </span>
      <span className={` ft-18 font-inter cms-tag-empty-state-content`}>
        {SHARE_GOOGLE_CONTACTS_TEXT}
      </span>
      <span className="font-inter cms-tag-empty-state-content">
        <a href={SHARE_GOOGLE_CONTACTS_URL} target="_blank" rel="noreferrer">
          Learn More
        </a>
      </span>
    </div>
  );
};

export default GoogleSpaceEmptyState;
