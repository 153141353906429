import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-use";
import { useParams, Prompt, useHistory } from "react-router-dom";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import filter from "lodash/filter";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";

//! Antd import
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Text from "antd/lib/typography/Text";
import List from "antd/lib/list";

//! User files
import { CONTACT_LIMIT } from "../../../../../common/constants";
import SearchComponent from "../../../../../common/SearchComponent";
import Loading from "../../../../../common/components/Loading";
import ContactCard from "../../../../../common/components/ContactCard/ContactCard";
import { AppContext } from "../../../../../AppContext";
import * as ActionTypes from "../../../../../common/actionTypes";
import useDebounce from "../../../../../common/useDebounce";
import {
  capitalizeWord,
  generateRandomColor,
  getNameTag,
  notificationToast,
} from "../../../../../common/utils";
import ContactCardSkeleton from "../../../../../common/components/ContactCardSkeleton/ContactCardSkeleton";
import NoContactPlaceholder from "./NoContactPlaceholder";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import { RemoveImageIcon } from "../../../../../assets/svg";

//! Graphql files
import { GET_ALL_CONTACTS } from "../../../graphql/queries/getContactsList";
import { ASSIGN_UNASSIGN_CONTACT_TO_GROUP } from "../../../graphql/mutations/assignOrUnassignContactToGroup";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";

const AddExistingContact = () => {
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState();
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [userContactsCount, setUserContactsCount] = useState(0);
  const [contactLoading, setContactLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [contactsAdded, setContactAdded] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const { pathname } = useLocation();
  const {
    state: {
      contacts,
      isExistingContact,
      currentSideTab,
      contactsCount,
      unassignedContacts,
      isDoneButtonClick,
      addedContactsCount,
    },
    dispatch,
  } = useContext(AppContext);
  const { groupId, spaceId } = useParams();
  const currentGroup = currentSideTab
    ? capitalizeWord(currentSideTab)
    : localStorage.getItem("currentGroup");
  const { push } = useHistory();

  const debounceTerm = useDebounce(searchTerm, 500);
  const [
    getExistingContacts,
    { data: allContacts, loading: existingContactLoading },
  ] = useLazyQuery(GET_ALL_CONTACTS, {
    fetchPolicy: "network-only",
    variables: {
      spaceId: parseInt(spaceId, 10),
      data: {
        limit: CONTACT_LIMIT,
        offset: offset,
        query: debounceTerm?.toLowerCase().trim() ?? null,
        sortBy: "ASC",
        sortOn: "firstName",
      },
    },
    onCompleted(data) {
      if (data) {
        setContactLoading(false);
        setPageLoading(false);
      }
    },
    onError() {
      setContactLoading(false);
      setPageLoading(false);
      push(`/contacts/${spaceId}/group/all`);
    },
  });

  const [addExistingContact, { loading: saveLoading }] = useMutation(
    ASSIGN_UNASSIGN_CONTACT_TO_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUP_INFO_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(groupId, 10),
          },
        },
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
        {
          query: GET_ALL_CONTACTS,
          variables: {
            // eslint-disable-next-line no-nested-ternary
            tag: null,
            spaceId: spaceId ? parseInt(spaceId, 10) : null,
            // eslint-disable-next-line no-nested-ternary
            groupId: groupId ? parseInt(groupId, 10) : null,
            data: {
              limit: CONTACT_LIMIT,
              offset: 0,
              sortBy: "ASC",
              sortOn: "firstName",
              query: null,
              noGroups: !!pathname.includes("no-groups"),
              deleted: !!pathname.includes("deleted-contacts"),
            },
          },
        },
      ],
      onCompleted() {
        dispatch({
          type: ActionTypes.SET_IS_EXISTING_CONTACT,
          data: false,
        });
      },
      onError() {
        setShowPrompt(true);
      },
    }
  );

  useEffect(() => {
    if (spaceId > 0) {
      getExistingContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (!existingContactLoading)
      if (allContacts?.getContacts?.data?.contacts) {
        const contactData = allContacts?.getContacts?.data?.contacts;
        const parsedData = JSON.parse(
          JSON.stringify(contactData),
          (key, value) => {
            if (key !== "__typename") {
              return value;
            }
          }
        );
        const previousContacts = cloneDeep(allUserContacts);
        setAllUserContacts(
          uniqBy([
            ...previousContacts,
            ...sortBy(parsedData, (e) => e?.firstName),
          ])
        );
        setUserContactsCount(allContacts?.getContacts?.data?.count);
      } else {
        setAllUserContacts([]);
      }
    dispatch({
      type: ActionTypes.SET_IS_EXISTING_CONTACT,
      data: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts]);

  useEffect(() => {
    if (debounceTerm !== undefined && debounceTerm !== null) {
      getExistingContacts();
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceTerm]);

  const addContactToGroup = (contactId) => {
    const contactData = find(allUserContacts, (e) => e?.id === contactId);
    const isContactUnassigned = find(
      unassignedContacts,
      (e) => e?.id === contactId
    );
    if (isContactUnassigned) {
      dispatch({
        type: ActionTypes.SET_UNASSIGNED_CONTACTS,
        data: filter(
          unassignedContacts,
          (contact) => contact?.id !== contactId
        ),
      });
    }
    setShowPrompt(true);
    setCount((prev) => prev + 1);
    setContactAdded([contactData, ...contactsAdded]);
    dispatch({
      type: ActionTypes.SET_ADDED_CONTACTS_COUNT,
      data: count + 1,
    });
    notificationToast({
      message: `Contact ${
        // eslint-disable-next-line prefer-template
        contactData?.firstName + " " + contactData?.lastName
      } added in ${currentGroup} group`,
      type: "Success",
    });
  };

  const removeContactFromGroup = (contactId) => {
    const contactData = find(allUserContacts, (e) => e?.id === contactId);
    setCount((prev) => prev - 1);
    const isContact = find(contacts, (e) => e?.id === contactId);
    if (isContact) {
      if (unassignedContacts?.length > 0) {
        // eslint-disable-next-line prefer-const
        let temp = cloneDeep(unassignedContacts);
        temp.push(isContact);
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: temp,
        });
      } else {
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: [isContact],
        });
      }
    }
    notificationToast({
      message: `Contact ${
        // eslint-disable-next-line prefer-template
        contactData?.firstName + " " + contactData?.lastName
      } removed from ${currentGroup} group`,
      type: "error",
    });
  };

  useEffect(() => {
    if (contacts?.length === 0 && unassignedContacts?.length === 0) {
      dispatch({
        type: ActionTypes.SET_IS_EXISTING_CONTACT,
        data: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight) &&
      allUserContacts.length < userContactsCount
    ) {
      setPageLoading(true);
      setOffset(allUserContacts?.length);
    }
  };

  window.addEventListener("beforeunload", function (e) {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    if (count > 0 && !isDoneButtonClick) {
      e.returnValue =
        "You have unsaved changes, are you sure you want to leave?";
    }
  });

  const handleRouteChange = (nextLocation) => {
    if (
      nextLocation &&
      !isDoneButtonClick &&
      count > 0 &&
      showPrompt &&
      !saveLoading
    ) {
      // eslint-disable-next-line no-alert
      const value = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (value) {
        dispatch({
          type: ActionTypes.SET_IS_EXISTING_CONTACT,
          data: false,
        });
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: {},
        });
        dispatch({
          type: ActionTypes.SET_ADDED_CONTACTS_COUNT,
          data: 0,
        });
        return true;
      }
      return false;
    }
    dispatch({
      type: ActionTypes.SET_IS_EXISTING_CONTACT,
      data: false,
    });
  };

  const contactUnAssigned = (contactId) => {
    if (contactId > 0) {
      setContactAdded(
        filter(contactsAdded, (contact) => contact?.id !== contactId)
      );
      dispatch({
        type: ActionTypes.SET_ADDED_CONTACTS_COUNT,
        data: count - 1,
      });
    }
  };

  const handleAssignUnAssignContacts = () => {
    const contactIds = contactsAdded?.map((val) => {
      return parseInt(val?.id, 10);
    });
    dispatch({
      type: ActionTypes.SET_ADDED_CONTACTS_COUNT,
      data: 0,
    });
    if (unassignedContacts?.length > 0) {
      setShowPrompt(false);
      const unassignedContactIds = unassignedContacts?.map((val) => {
        return parseInt(val?.id, 10);
      });
      addExistingContact({
        variables: {
          id: parseInt(groupId, 10),
          contactIds: unassignedContactIds,
          type: "UNASSIGN",
        },
      }).then(() => {
        dispatch({
          type: ActionTypes.SET_CONTACTS,
          data: difference(contacts, unassignedContacts),
        });
        dispatch({
          type: ActionTypes.SET_CONTACTS_COUNT,
          data: difference(contacts, unassignedContacts)?.length,
        });
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: {},
        });
        push(`/contacts/${spaceId}/group/${groupId}/settings`);
      });
    }
    if (contactIds?.length > 0) {
      setShowPrompt(false);
      addExistingContact({
        variables: {
          id: parseInt(groupId, 10),
          contactIds,
          type: "ASSIGN",
        },
      }).then(() => {
        push(`/contacts/${spaceId}/group/${groupId}/settings`);
      });
    } else {
      dispatch({
        type: ActionTypes.SET_IS_EXISTING_CONTACT,
        data: false,
      });
    }
    dispatch({
      type: ActionTypes.SET_IS_EXISTING_CONTACT,
      data: false,
    });
  };

  useEffect(() => {
    if (contactsAdded?.length === 0 || unassignedContacts?.length === 0) {
      setShowPrompt(false);
    }
  }, [contactsAdded, unassignedContacts]);

  return (
    <div className="cms-existing-contact-wrapper">
      <Prompt when={count > 0} message={handleRouteChange} />
      <div className="cms-existing-contact-wrapper-header">
        <div className="cms-contact-form-close">
          <span className="font-inter cms-page-title username">
            Edit group contacts
          </span>
          <IconButton
            iconClass="font-inter cms-form-save-btn"
            text="Save"
            loading={saveLoading}
            handleClick={handleAssignUnAssignContacts}
            disable={isEmpty(unassignedContacts) && addedContactsCount === 0}
          />
        </div>
        <div className="cms-search-box">
          <SearchComponent setSearchTerm={setSearchTerm} />
        </div>
      </div>
      <div
        className={
          contactsAdded?.length > 0
            ? "cms-existing-contact-wrapper-select-contact-list mb-24"
            : "hide"
        }
      >
        <div className="cms-contact-slider d-flex-center cms-contact-scrollbar">
          {contactsAdded?.map((contact) => (
            <div className="cms-contact-slider-inner" key={contact?.id}>
              <span className="position-relative">
                <UserAvatar
                  name={contact?.firstName}
                  size={48}
                  color={generateRandomColor(parseInt(contact?.id, 10))}
                  fontSize={19}
                  className="mb-4"
                />
                <span className="cms-contact-remove-icon">
                  <RemoveImageIcon
                    className="cursor-pointer"
                    onClick={() => contactUnAssigned(contact?.id)}
                  />
                </span>
              </span>
              <span className="font-inter cms-contact-slider-text">
                {contact?.firstName || "No name"}
              </span>
            </div>
          ))}
        </div>
      </div>

      {
        // eslint-disable-next-line no-nested-ternary
        contactLoading && offset === 0 ? (
          <ContactCardSkeleton loading={contactLoading} active />
        ) : allUserContacts?.length > 0 ? (
          <div
            className="cms-existing-contact-list cms-contact-scrollbar"
            onScroll={(e) => handleInfiniteScroll(e)}
          >
            <List
              className="cms-contacts-card-list"
              dataSource={allUserContacts}
              renderItem={(item) => {
                const contactLink = `/contacts/${spaceId}/group/${groupId}/contact/${item.id}`;
                const isSelected = pathname === contactLink;
                const {
                  firstName,
                  middleName,
                  lastName,
                  photo,
                  jobTitle,
                  id,
                  company,
                } = item;
                const contactAdded = find(contacts, (e) => e?.id === id);
                const contactsFromExistingPage = find(
                  contactsAdded,
                  (e) => e?.id === id
                );
                const unAssigned = find(
                  unassignedContacts,
                  (e) => e?.id === id
                );
                const name =
                  // eslint-disable-next-line prefer-template
                  (firstName ?? "") +
                  " " +
                  (middleName ?? "") +
                  " " +
                  (lastName ?? "");
                const title = (
                  <Text className="username cms-font-weight-medium font-inter">
                    {getNameTag(name, company)}
                  </Text>
                );
                const description = (
                  <Row className="description-row">
                    {jobTitle && (
                      <Col span={24} className="designation-col">
                        <Text type="secondary" className="designation">
                          {jobTitle}
                        </Text>
                      </Col>
                    )}
                  </Row>
                );
                return (
                  <ContactCard
                    firstName={firstName}
                    isSelected={isSelected}
                    company={company}
                    photo={photo}
                    title={title}
                    description={description}
                    jobTitle={jobTitle}
                    contactLink={contactLink}
                    size={48}
                    toAdd="true"
                    addContactToGroup={addContactToGroup}
                    contactId={id}
                    contactAdded={contactAdded}
                    removeContactFromGroup={removeContactFromGroup}
                    isExistingContact={isExistingContact}
                    contactsFromExistingPage={contactsFromExistingPage}
                    unAssigned={unAssigned}
                    actions
                  />
                );
              }}
            />
          </div>
        ) : (
          allContacts?.getContacts?.data?.count === 0 && (
            <NoContactPlaceholder
              contactsCount={contactsCount}
              groupId={groupId}
              spaceId={spaceId}
              searchTerm={searchTerm}
              isExistingPage
            />
          )
        )
      }

      {pageLoading && <Loading />}
    </div>
  );
};

export default AddExistingContact;
