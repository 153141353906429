import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
  mutation createGroup($name: String!, $type: VIEW!, $spaceId: Int!) {
    createGroup(data: { name: $name, type: $type, spaceId: $spaceId }) {
      data {
        name
        source
        sourceId
        ownerId
        id
        createdAt
        updatedAt
        updatedAt
      }
      message
      status
    }
  }
`;

export const EDIT_GROUP = gql`
  mutation editGroup($groupId: Int!, $name: String, $type: VIEW) {
    editGroup(data: { name: $name, type: $type }, groupId: $groupId) {
      data {
        name
        source
        sourceId
        ownerId
        spaceId
        id
        createdAt
        updatedAt
      }
      message
      status
    }
  }
`;

export const REMOVE_GROUP = gql`
  mutation removeGroup($spaceId: Int!, $groupId: Int!) {
    removeGroup(groupId: $groupId, spaceId: $spaceId) {
      message
      status
    }
  }
`;

export const GENERATE_CONTACT_PUBLIC_LINK = gql`
  mutation generateContactPublicLink($id: Int!, $expiryDate: DateTime) {
    generateContactPublicLink(id: $id, expiryDate: $expiryDate) {
      link
      message
      status
    }
  }
`;
