import React, { useContext } from "react";
import List from "antd/lib/list";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-use";

//! User files
import {
  MOBILE_DEVICE_CONTACTS,
  SOURCE_MOBILE,
} from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import { TagsIcon } from "../../../../../assets/svg";
import { SET_CURRENT_SELECTED_TAG } from "../../../../../common/actionTypes";
import CountTag from "../../../../../common/components/CountTag";
import { capitalizeWord } from "../../../../../common/utils";

function TagsListItems({ tags, spaceId }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { dispatch } = useContext(AppContext);
  const currentTag = decodeURIComponent(pathname?.split("/")[4]);

  const handleTagClick = (link, tag) => {
    dispatch({ type: SET_CURRENT_SELECTED_TAG, data: capitalizeWord(tag) });
    history.push(link);
  };

  return (
    <div className="tags-list">
      <List
        dataSource={tags}
        renderItem={(item) => {
          const { tag, contacts } = item;
          const pathLink = `/contacts/${spaceId}/tag/${tag}`;
          const isSelected = currentTag === tag;
          const tagTitle = (
            <div className="cms-tag-title">
              <div
                className="cms-tag-title-text"
                title={tag === SOURCE_MOBILE ? MOBILE_DEVICE_CONTACTS : tag}
              >
                {tag === SOURCE_MOBILE ? MOBILE_DEVICE_CONTACTS : tag}
              </div>
              <CountTag count={contacts} />
            </div>
          );
          return (
            <List.Item
              onClick={() =>
                handleTagClick(
                  pathLink,
                  tag === SOURCE_MOBILE ? MOBILE_DEVICE_CONTACTS : tag
                )
              }
              className={clsx(isSelected && "selected-list active-bg")}
            >
              <List.Item.Meta avatar={<TagsIcon />} title={tagTitle} />
            </List.Item>
          );
        }}
      />
    </div>
  );
}

export default TagsListItems;
