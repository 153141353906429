//! Commenting eslint on top as it's repeating more than 4 times
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import includes from "lodash/includes";
import find from "lodash/find";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { useLocation, useWindowSize } from "react-use";
import filter from "lodash/filter";

//! Antd import
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import DatePicker from "antd/lib/date-picker";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

//! User files
import {
  ContactPerson,
  AddRow,
  AddGroup,
  RemoveImageIcon,
  CloseCircle,
  DownArrowIcon,
  CalendarImage,
  TagIcon,
  Folder,
} from "../../../assets/svg";
import Loading from "../Loading";
import { capitalizeWord, notificationToast } from "../../utils";
import { CONTACT_LIMIT, GROUP_LIMIT, WINDOW_SIZE } from "../../constants";

//! Graphql imports
import { GENERATE_SIGNED_URL_FOR_PICTURE } from "./graphql/mutations/generateSignedUrlForPicture";
import { GET_TAGS_LIST } from "../../../modules/main/graphql/queries/getTagsList";
import { GET_GROUPS_FOR_CONTACT } from "../../../modules/main/graphql/queries/getGroupsForContact";

const { TextArea } = Input;
const { Option } = Select;
const phoneType = [
  { value: "home", label: "Home" },
  { value: "work", label: "Work" },
  { value: "other", label: "Other" },
];

const emailType = [
  { value: "personal", label: "Personal" },
  { value: "work", label: "Work" },
  { value: "other", label: "Other" },
];
const addressType = [
  { value: "home", label: "Home" },
  { value: "work", label: "Work" },
  { value: "other", label: "Other" },
];

const phoneTypeSelect = (
  <Select
    options={phoneType}
    className="select-before"
    defaultValue="mobile"
    getPopupContainer={() => document.getElementById("phoneNumber")}
    suffixIcon={<DownArrowIcon />}
  />
);

const websiteType = (
  <Select
    className="select-before"
    getPopupContainer={() => document.getElementById("website")}
    suffixIcon={<DownArrowIcon />}
  >
    <Option value="personal">Personal</Option>
    <Option value="work">Work</Option>
    <Option value="other">Other</Option>
  </Select>
);

const emailTypeSelect = (
  <Select
    options={emailType}
    className="select-before"
    defaultValue="work"
    getPopupContainer={() => document.getElementById("email")}
    suffixIcon={<DownArrowIcon />}
  />
);

const socialProfileType = (
  <Select
    className="select-before"
    getPopupContainer={() => document.getElementById("socialProfile")}
    suffixIcon={<DownArrowIcon />}
  >
    <Option value="facebook">Facebook</Option>
    <Option value="google">Google</Option>
    <Option value="instagram">Instagram</Option>
    <Option value="linkedIn">LinkedIn</Option>
    <Option value="twitter">Twitter</Option>
    <Option value="customUrl">Custom URL</Option>
  </Select>
);

const ContactForm = ({
  handleContactSubmit,
  name,
  initialValues,
  contactLoading,
  form,
  contactCreated,
  userGroups,
  setUserGroups,
  setIsPromptRequired,
  setIsImageUploading,
  spaceId,
  contactId,
  submitRef,
  setUpdateButton,
}) => {
  const { width } = useWindowSize();
  const [imageLoading, setImageLoading] = useState(false);
  const [options, setOptions] = useState(addressType);
  const [profileImage, setProfileImage] = useState();
  const [fileContent, setFileContent] = useState();
  const [tags, setTags] = useState();
  const [contactTags, setContactTags] = useState([]);
  const [groups, setGroups] = useState();
  const [contactGroups, setContactGroups] = useState();
  const [focusField, setFocusField] = useState();
  const [offset, setOffset] = useState(0);
  const [tagOffset, setTagOffset] = useState(0);
  const [tagsLength, setTagsLength] = useState(0);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const selectedField = params?.get("field");
  const birthdayRef = useRef();
  const anniversaryRef = useRef();
  const addressRef = useRef();

  const [generateSignedUrl] = useMutation(GENERATE_SIGNED_URL_FOR_PICTURE, {
    onCompleted({ generateSignedUrlForPicture }) {
      if (generateSignedUrlForPicture?.url) {
        const { signedRequest, url } = generateSignedUrlForPicture;
        const xhr = new XMLHttpRequest();

        xhr.open("PUT", signedRequest);
        xhr.setRequestHeader("Content-Type", fileContent?.type);
        xhr.setRequestHeader("x-amz-acl", "public-read");
        xhr.withCredentials = false;

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status !== 200) {
              notificationToast({
                message: "Profile image upload failed!",
                type: "error",
              });
            }
          }
        };

        xhr.send(fileContent);
        setTimeout(() => {
          setImageLoading(false);
          setProfileImage(url);
          setIsImageUploading(false);
          setUpdateButton && setUpdateButton(false);
        }, 2000);
        localStorage.setItem("userProfile", url);
      }
    },
    onError() {
      setImageLoading(false);
    },
  });

  //! Have passed constant value knowingly
  const { data: userContactTags } = useQuery(GET_TAGS_LIST, {
    fetchPolicy: "network-only",
    variables: {
      offset: tagOffset,
      limit: CONTACT_LIMIT,
      spaceId: parseInt(spaceId, 10),
    },
  });

  const { data: userContactGroups, loading: groupLoading } = useQuery(
    GET_GROUPS_FOR_CONTACT,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: 0,
        limit: GROUP_LIMIT,
        spaceId: parseInt(spaceId, 10),
      },
    }
  );

  useEffect(() => {
    if (selectedField) {
      setFocusField(selectedField);
      switch (selectedField) {
        case "date":
          birthdayRef?.current?.focus();
          break;
        case "address":
          addressRef?.current?.focus();
          break;
        default:
          break;
      }
    }
  }, [selectedField]);

  useEffect(() => {
    if (userContactTags?.tagList?.data?.tags) {
      const allTags = [];
      const onlyTags = [];
      if (tags?.length > 0) {
        allTags?.push(...tags);
      }
      if (contactTags?.length > 0) {
        onlyTags?.push(...contactTags);
      }
      const tagsFilter = filter(
        userContactTags?.tagList?.data?.tags,
        (e) => e?.tag !== "source-mobile"
      );
      setTagsLength(tagsLength + userContactTags?.tagList?.data?.tags?.length);
      // eslint-disable-next-line array-callback-return
      tagsFilter?.map((data) => {
        allTags.push(
          <Option key={data?.tag} value={data?.tag}>
            {data?.tag}
          </Option>
        );
        onlyTags.push(data?.tag);
      });
      setTags(uniqBy(allTags, "key"));
      setContactTags(onlyTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContactTags]);

  useEffect(() => {
    if (userContactGroups?.getGroupsForContact?.groups) {
      const allGroups = [];
      const onlyGroups = [];
      if (groups?.length > 0) {
        allGroups?.push(...groups);
      }
      if (contactGroups?.length > 0) {
        onlyGroups?.push(...contactGroups);
      }
      // eslint-disable-next-line array-callback-return
      userContactGroups?.getGroupsForContact?.groups?.map((data, i) => {
        allGroups.push(
          // eslint-disable-next-line react/no-array-index-key
          <Option key={i} value={data?.name}>
            {capitalizeWord(data?.name)}
          </Option>
        );
        onlyGroups.push(data?.id);
      });
      setGroups(allGroups);
      setContactGroups(onlyGroups);
      // eslint-disable-next-line no-unused-expressions
      setUserGroups &&
        setUserGroups({
          ...userGroups,
          ...userContactGroups?.getGroupsForContact?.groups,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContactGroups]);

  useEffect(() => {
    const image = localStorage.getItem("userProfile");
    // eslint-disable-next-line no-unused-expressions
    image && setProfileImage(image);
  }, []);

  useEffect(() => {
    if (initialValues?.photo) {
      setProfileImage(initialValues?.photo);
    }
    if (initialValues?.addresses) {
      // eslint-disable-next-line array-callback-return
      initialValues?.addresses?.map((currentAddress) => {
        const obj = {
          value: currentAddress?.label,
          label:
            currentAddress?.label?.charAt(0)?.toUpperCase() +
            currentAddress?.label?.slice(1),
        };
        setOptions(uniqBy([...options, obj], "value"));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (contactCreated) {
      localStorage.removeItem("userProfile");
      setProfileImage();
    }
  }, [contactCreated]);

  const beforeUpload = (extension, size) => {
    const isJpgOrPng =
      extension === "jpeg" || extension === "png" || extension === "jpg";
    if (!isJpgOrPng) {
      notificationToast({
        message: "You can only upload JPG/PNG file!",
        type: "error",
      });
    }
    const isLt2M = size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notificationToast({
        message: "Image must be smaller than 2MB!",
        type: "error",
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleFileUpload = async (image) => {
    setFileContent(image);
    setImageLoading(true);
    const fileName = `test/${image?.name}`;
    const imgExt = image?.name?.split(".")?.pop();
    const canUpload = beforeUpload(imgExt, image?.size);
    if (canUpload) {
      setIsImageUploading(true);
      await generateSignedUrl({
        variables: {
          data: {
            extension: image?.name.split(".").pop(),
            key: fileName,
          },
        },
      });
    } else {
      setImageLoading(false);
      setIsImageUploading(false);
    }
  };

  const disabledDate = (current) => {
    //! Can not select days before today
    return current > moment().endOf("day");
  };

  useEffect(() => {
    if (initialValues?.tags) {
      const tagOption = [];
      initialValues?.tags.map((tag) =>
        tagOption.push(
          <Option key={tag} value={tag}>
            {tag}
          </Option>
        )
      );
      setContactTags(initialValues?.tag);
    }
    if (initialValues?.groups) {
      const groupOption = [];
      const groupIds = [];
      // eslint-disable-next-line array-callback-return
      initialValues?.groups.map((group) => {
        const groupId = find(userGroups, (e) => {
          if (e?.name === group) {
            return e?.id;
          }
        });
        groupOption.push(
          <Option key={group} value={group}>
            {group}
          </Option>
        );
        groupIds.push(groupId?.id);
      });
      setContactGroups(groupIds);
    }
  }, [initialValues, userGroups]);

  const handleTag = (value) => {
    if (!includes(contactTags, value) && value?.trim()?.length > 0) {
      const newTag = (
        <Option value={value} key={value}>
          {value}
        </Option>
      );
      setTags(uniqBy([...tags, newTag], "props.value"));
      setContactTags([contactTags, value]);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const field = Object.keys(changedValues)[0];
    const fieldValue = get(initialValues, `${field}`);

    if (
      allValues?.firstName?.trim()?.length === 0 ||
      fieldValue === changedValues[field] ||
      (fieldValue === null && changedValues[field]?.length === 0) ||
      isEqual(fieldValue, allValues[field])
    ) {
      setUpdateButton && setUpdateButton(true);
      setIsPromptRequired(false);
    } else {
      setUpdateButton && setUpdateButton(false);
      setIsPromptRequired(true);
    }
  };

  const removeProfileImage = () => {
    setProfileImage();
    localStorage.setItem("userProfile", "");
    setUpdateButton && setUpdateButton(false);
  };

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      groups?.length < userContactGroups?.getGroupsForContact?.count
    ) {
      setOffset(offset + CONTACT_LIMIT);
    }
  };

  const handleInfiniteScrollForTags = (e) => {
    const element = e.target;
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      tagsLength < userContactTags?.tagList?.data?.count
    ) {
      setTagOffset(tagOffset + CONTACT_LIMIT);
    }
  };

  return (
    <Spin
      size="large"
      spinning={groupLoading}
      indicator={<LoadingOutlined spin />}
      className="cb-loading-wrapper"
    >
      <Form
        form={form}
        name={name}
        onFinish={(values) => handleContactSubmit(values, setProfileImage)}
        onValuesChange={handleValuesChange}
        initialValues={initialValues}
        className={`${
          contactLoading && "cms-add-edit-contact-form"
        } cms-user-contact-form`}
        onClick={() => setFocusField()}
        onKeyDown={(e) => {
          if (e?.key === "Enter" && e?.target?.tagName !== "TEXTAREA") {
            e?.preventDefault();
          }
        }}
      >
        <Row className="cms-form-row">
          <div className="cms-add-photo">
            <div className="cms-upload-btn">
              {imageLoading ? (
                <span className="cms-upload-btn-icon">
                  <Loading />
                </span>
              ) : (
                <>
                  {profileImage && (
                    <span
                      className="cms-remove-image-btn cursor-pointer"
                      onClick={removeProfileImage}
                    >
                      <RemoveImageIcon />
                    </span>
                  )}
                  <span className="cms-upload-btn-icon mr-12 cursor-pointer">
                    <input
                      id="upload-file"
                      className="cms-input-file"
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={(e) => handleFileUpload(e.target.files[0])}
                    />
                    <AddGroup />
                    {profileImage && (
                      <img
                        name="userProfile"
                        className="cms-user-profile"
                        src={profileImage}
                        alt="profile"
                      />
                    )}
                  </span>
                  <span className="font-inter cms-upload-btn-photo-text">
                    {profileImage ? "Update photo" : "Add photo"}
                  </span>
                </>
              )}
            </div>
          </div>
        </Row>
        <Row className="cms-form-row">
          <Col span={8}>
            <Form.Item
              name="firstName"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input placeholder="First name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="middleName" className="cms-mname">
              <Input placeholder="Middle name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="lastName" className="cms-lname">
              <Input placeholder="Last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="jobTitle">
              <Input placeholder="Job title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="company" className="ml-8">
              <Input placeholder="Company" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="cms-form-row">
          <Col span={24}>
            <div className="position-relative" id="group">
              <span className="cms-tag-input-icon">
                <Folder />
              </span>
              <Form.Item
                name="groups"
                label="Add to groups"
                colon={false}
                className="cms-add-group"
              >
                <Select
                  mode="multiple"
                  defaultValue={contactGroups}
                  placeholder={<span className="ml-24">Add to groups</span>}
                  getPopupContainer={() => document.getElementById("group")}
                  onPopupScroll={handleInfiniteScroll}
                >
                  {groups?.length > 0 && groups}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className="cms-form-row">
          <Col span={24}>
            <div className="position-relative" id="tag">
              <span className="cms-tag-input-icon">
                <TagIcon />
              </span>
              <Form.Item name="tags" label="Assign tags" colon={false}>
                <Select
                  className="cms-form-tags"
                  mode="tags"
                  onSelect={handleTag}
                  placeholder={<span className="ml-24">Assign tags</span>}
                  getPopupContainer={() => document.getElementById("tag")}
                  onPopupScroll={handleInfiniteScrollForTags}
                >
                  {tags?.length > 0 && tags}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <div className="cms-contact-detail">
          <div className="cms-contact-detail-header">
            <span className="cms-contact-detail-header-p-icon">
              <ContactPerson />
            </span>
            <span className="font-inter username">Contact Details</span>
          </div>
          <div className="cms-contact-detail-form-details">
            <div className="cms-contact-detail-inside">
              <div className="cms-contact-form-label-wrapper">
                <label className="cms-contact-form-label">Phone number</label>
              </div>
              <Form.List name="phoneNumbers">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      (field, name, fieldKey, index, ...restField) => (
                        <Form.Item
                          colon={false}
                          required={false}
                          key={field.key}
                          {...restField}
                        >
                          <Row className="cms-form-row cms-form-align">
                            <Col span={width <= WINDOW_SIZE ? 22 : 23}>
                              <Form.Item
                                {...field}
                                name={[name, "value"]}
                                fieldKey={[fieldKey, "value"]}
                                noStyle
                              >
                                <Input
                                  addonBefore={
                                    <div
                                      className="position-relative"
                                      id="phoneNumber"
                                    >
                                      <Form.Item
                                        name={[name, "type"]}
                                        initialValue="mobile"
                                        noStyle
                                      >
                                        {phoneTypeSelect}
                                      </Form.Item>
                                    </div>
                                  }
                                  placeholder="Phone"
                                  className="full-width"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={width <= WINDOW_SIZE ? 2 : 1}
                              className="cms-flex-end"
                            >
                              <CloseCircle
                                className="cms-remove-item"
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      )
                    )}
                    <Form.Item>
                      <Col
                        className={
                          width <= WINDOW_SIZE ? "ant-col-24" : "ant-col-23"
                        }
                      >
                        <Button
                          onClick={() => add()}
                          className={`${
                            focusField === "phone" ? "cms-field-focus" : ""
                          } cms-add-row`}
                        >
                          <span className="cms-add-row-add-icon">
                            <AddRow />
                          </span>
                          <span>add phone number</span>
                        </Button>
                      </Col>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div className="cms-contact-detail-inside">
              <div className="cms-contact-form-label-wrapper">
                <label className="cms-contact-form-label">Email Address</label>
              </div>
              <Form.List name="emailAddress">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      (field, name, fieldKey, index, ...restField) => (
                        <Form.Item
                          {...restField}
                          colon={false}
                          required={false}
                          key={field.key}
                        >
                          <Row className="cms-form-row cms-form-align">
                            <Col span={width <= WINDOW_SIZE ? 22 : 23}>
                              <Form.Item
                                {...field}
                                name={[name, "value"]}
                                fieldKey={[fieldKey, "value"]}
                                noStyle
                              >
                                <Input
                                  addonBefore={
                                    <div
                                      className="position-relative"
                                      id="email"
                                    >
                                      <Form.Item
                                        name={[name, "type"]}
                                        initialValue="work"
                                        noStyle
                                      >
                                        {emailTypeSelect}
                                      </Form.Item>
                                    </div>
                                  }
                                  placeholder="Email"
                                  className="full-width"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={width <= WINDOW_SIZE ? 2 : 1}
                              className="cms-flex-end"
                            >
                              <CloseCircle
                                className="cms-remove-item"
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      )
                    )}
                    <Form.Item>
                      <Col
                        className={
                          width <= WINDOW_SIZE ? "ant-col-24" : "ant-col-23"
                        }
                      >
                        <Button
                          onClick={() => add()}
                          className={`${
                            focusField === "email" ? "cms-field-focus" : ""
                          } cms-add-row`}
                        >
                          <span className="cms-add-row-add-icon">
                            <AddRow />
                          </span>
                          <span>add email address</span>
                        </Button>
                      </Col>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div className="cms-contact-detail-inside">
              <div className="cms-contact-form-label-wrapper">
                <label className="cms-contact-form-label">Notes</label>
              </div>
              <Row className="cms-form-row cms-form-align">
                <Col span={width <= WINDOW_SIZE ? 22 : 23}>
                  <Form.Item required={false} key="notes" name="notes">
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      placeholder="Notes"
                      className="full-width"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="cms-contact-detail-inside">
              <div className="cms-contact-form-label-wrapper">
                <label className="cms-contact-form-label">Website</label>
              </div>
              <Form.List name="website">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      (field, name, fieldKey, index, ...restField) => (
                        <Form.Item
                          {...restField}
                          colon={false}
                          required={false}
                          key={field.key}
                        >
                          <Row className="cms-form-row cms-form-align">
                            <Col span={width <= WINDOW_SIZE ? 22 : 23}>
                              <Form.Item
                                {...field}
                                name={[name, "value"]}
                                fieldKey={[fieldKey, "value"]}
                                noStyle
                              >
                                <Input
                                  addonBefore={
                                    <div
                                      className="position-relative"
                                      id="website"
                                    >
                                      <Form.Item
                                        name={[name, "type"]}
                                        initialValue="work"
                                        noStyle
                                      >
                                        {websiteType}
                                      </Form.Item>
                                    </div>
                                  }
                                  placeholder="Website"
                                  className="full-width"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={width <= WINDOW_SIZE ? 2 : 1}
                              className="cms-flex-end"
                            >
                              <CloseCircle
                                className="cms-remove-item"
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      )
                    )}
                    <Form.Item>
                      <Col
                        className={
                          width <= WINDOW_SIZE ? "ant-col-24" : "ant-col-23"
                        }
                      >
                        <Button
                          onClick={() => add()}
                          className={`${
                            focusField === "website" ? "cms-field-focus" : ""
                          } cms-add-row`}
                        >
                          <span className="cms-add-row-add-icon">
                            <AddRow />
                          </span>
                          <span>add website</span>
                        </Button>
                      </Col>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div className="cms-contact-detail-inside mr-24">
              <Row className="cms-form-row cms-form-align">
                <Col span={12}>
                  <Form.Item
                    name="birthday"
                    label="Birthday"
                    colon={false}
                    className="cms-input-dropdown cms-date-field mr-5"
                  >
                    <DatePicker
                      format="MM-DD-YYYY"
                      placeholder="MM-DD-YYYY"
                      disabledDate={disabledDate}
                      ref={birthdayRef}
                      suffixIcon={<CalendarImage />}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="anniversary"
                    label="Anniversary"
                    colon={false}
                    className="cms-input-dropdown cms-date-field ml-5"
                  >
                    <DatePicker
                      format="MM-DD-YYYY"
                      placeholder="MM-DD-YYYY"
                      disabledDate={disabledDate}
                      ref={anniversaryRef}
                      suffixIcon={<CalendarImage />}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="cms-contact-detail-inside">
              <div className="cms-contact-form-label-wrapper">
                <label className="cms-contact-form-label">
                  Social Profiles
                </label>
              </div>
              <Form.List name="socialProfile">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      (field, name, fieldKey, index, ...restField) => (
                        <Form.Item
                          {...restField}
                          colon={false}
                          required={false}
                          key={field.key}
                        >
                          <Row className="cms-form-row cms-form-align">
                            <Col span={width <= WINDOW_SIZE ? 22 : 23}>
                              <Form.Item
                                {...field}
                                name={[name, "value"]}
                                fieldKey={[fieldKey, "value"]}
                                noStyle
                              >
                                <Input
                                  addonBefore={
                                    <div
                                      className="position-relative"
                                      id="socialProfile"
                                    >
                                      <Form.Item
                                        name={[name, "type"]}
                                        initialValue="facebook"
                                        noStyle
                                      >
                                        {socialProfileType}
                                      </Form.Item>
                                    </div>
                                  }
                                  placeholder="facebook.com/exampleprofile"
                                  className="full-width"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={width <= WINDOW_SIZE ? 2 : 1}
                              className="cms-flex-end"
                            >
                              <CloseCircle
                                className="cms-remove-item"
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      )
                    )}
                    <Form.Item>
                      <Col
                        className={
                          width <= WINDOW_SIZE ? "ant-col-24" : "ant-col-23"
                        }
                      >
                        <Button
                          onClick={() => add()}
                          className={`${
                            focusField === "socialProfile"
                              ? "cms-field-focus"
                              : ""
                          } cms-add-row`}
                        >
                          <span className="cms-add-row-add-icon">
                            <AddRow />
                          </span>
                          <span>add social profile</span>
                        </Button>
                      </Col>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div className="cms-contact-detail-inside">
              <div className="cms-contact-form-label-wrapper">
                <label className="cms-contact-form-label">Address</label>
              </div>
              <Form.List name="addresses">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      (field, name, fieldKey, index, ...restField) => (
                        <Form.Item
                          {...restField}
                          colon={false}
                          required={false}
                          className="cms-input-dropdown"
                          key={field.key}
                        >
                          <Row
                            className="cms-form-row cms-form-align"
                            key={fieldKey}
                          >
                            <Col span={width <= WINDOW_SIZE ? 22 : 23}>
                              <Row>
                                <Col span={5}>
                                  <div
                                    className="position-relative full-height"
                                    id="address"
                                  >
                                    <Form.Item
                                      name={[name, "label"]}
                                      initialValues="home"
                                      fieldKey={[fieldKey, "value"]}
                                      noStyle
                                    >
                                      <Select
                                        className="address-select-before"
                                        defaultValue="home"
                                        getPopupContainer={() =>
                                          document.getElementById("address")
                                        }
                                        options={options}
                                        suffixIcon={<DownArrowIcon />}
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col span={19} className="address-input">
                                  {contactId && (
                                    <Form.Item
                                      name={[name, "contactId"]}
                                      className="hide"
                                      initialValue={parseInt(contactId, 10)}
                                    >
                                      <Input type="hidden" />
                                    </Form.Item>
                                  )}
                                  <Form.Item name={[name, "line1"]} noStyle>
                                    <Input
                                      placeholder="Appartment/Street"
                                      ref={addressRef}
                                    />
                                  </Form.Item>
                                  <Form.Item name={[name, "line2"]} noStyle>
                                    <Input placeholder="Locality" />
                                  </Form.Item>
                                  <Input.Group compact>
                                    <Form.Item name={[name, "city"]} noStyle>
                                      <Input
                                        placeholder="City"
                                        className="city-input width-percent-50"
                                      />
                                    </Form.Item>
                                    <Form.Item name={[name, "zipCode"]} noStyle>
                                      <Input
                                        placeholder="Pin code"
                                        className="width-percent-50"
                                      />
                                    </Form.Item>
                                  </Input.Group>
                                  <Input.Group compact>
                                    <Form.Item name={[name, "state"]} noStyle>
                                      <Input
                                        placeholder="State"
                                        className="city-input width-percent-50"
                                      />
                                    </Form.Item>
                                    <Form.Item name={[name, "country"]} noStyle>
                                      <Input
                                        placeholder="Country"
                                        className="width-percent-50"
                                      />
                                    </Form.Item>
                                  </Input.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              span={width <= WINDOW_SIZE ? 2 : 1}
                              className="cms-flex-end"
                            >
                              <CloseCircle
                                className="cms-remove-item"
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      )
                    )}
                    <Form.Item>
                      <Col className="ant-col-23">
                        <Button onClick={() => add()} className="cms-add-row">
                          <span className="cms-add-row-add-icon">
                            <AddRow />
                          </span>
                          <span>add address</span>
                        </Button>
                      </Col>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>
          <Button className="hide" ref={submitRef} htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default ContactForm;
