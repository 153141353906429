import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useWindowSize } from "react-use";
import isInteger from "lodash/isInteger";
import find from "lodash/find";

//! Ant Imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Text from "antd/lib/typography/Text";
import Dropdown from "antd/lib/dropdown";

//! User Files
import {
  Inquiry,
  Settings,
  TagsIcon,
  Logout,
  DeletedContactIcon,
  DeleteTagIcon,
  AllGroupContact,
  EditIcon,
  ContactCloseIcon,
} from "../../../../../assets/svg";
import SearchComponent from "../../../../../common/SearchComponent";
import {
  capitalizeWord,
  hasGroupRight,
  isPlanActive,
  hasSpaceRight,
  getSearchParams,
} from "../../../../../common/utils";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { AppContext } from "../../../../../AppContext";
import {
  ADD_CONTACT,
  ADD_EXISTING_CONTACT,
  ROUTES,
  SPACE,
  UPGRADE_PLAN_TITLE,
  WINDOW_SIZE,
  DELETE_TAG,
  MOBILE_TAB_SIZE,
} from "../../../../../common/constants";
import TagMenu from "../../../../../common/components/TagMenu/TagMenu";
import { HasRight } from "../../../../../common/HasRight";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";
import client from "../../../../../apollo";

//! Graphql files
import { REMOVE_TAG } from "../../../graphql/mutations/removeTag";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";

const ContactListHeader = ({
  group,
  tag,
  groupId,
  setSearchTerm,
  removeContact,
  addMultipleContact,
  spaceId,
  tagName,
  title,
  icon,
  remindersCount,
  searchTerm,
}) => {
  const groupName = localStorage.getItem("currentGroup");
  const { push } = useHistory();
  const [data, setData] = useState();
  const [tagModal, setOpenTagModal] = useState(false);
  const { pathname } = useLocation();
  const { search } = useLocation();
  const params = getSearchParams(search) || "";

  useEffect(() => {
    if (params) {
      setSearchTerm(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const url = new URL(window.location);
    if (searchTerm) {
      url?.searchParams.set("search", searchTerm);
      window.history.pushState({}, "", url);
    } else {
      url?.searchParams.delete("search");
      window.history.pushState({}, "", url);
    }
  }, [pathname, searchTerm]);

  const {
    state: {
      userGroupPermission,
      userSubscriptionInfo,
      currentUser,
      userSpacePermission,
      contactsCount,
      tagsList,
    },
    dispatch,
  } = useContext(AppContext);
  const isAll = pathname.includes("all");
  const isAllContact = pathname.includes("/contacts/all");
  const { width } = useWindowSize();
  const currentSpaceId =
    localStorage.getItem("currentSpace") || currentUser?.spaceId;
  const doneRef = useRef();
  const editRef = useRef();

  useEffect(() => {
    if (groupName && data && data?.length) {
      setData([{ ...data[0], name: groupName }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  async function fetchData() {
    await client
      .query({
        query: GET_GROUP_INFO_V2,
        variables: { id: parseInt(groupId, 10) },
      })
      .then((res) => {
        setData([res?.data?.groupInfoV2]);
      })
      .catch(() => {});
  }
  useEffect(() => {
    if (
      groupId !== "all" &&
      groupId !== "new" &&
      groupId !== null &&
      isInteger(groupId) &&
      group
    ) {
      fetchData();
    } else {
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const handleRouteChange = (
    route,
    checkCondition = false,
    planStatus,
    endDate
  ) => {
    if (checkCondition) {
      if (isPlanActive(planStatus, endDate)) {
        push(route);
      } else {
        dispatch({
          type: SET_SPACE_PLAN_STATUS,
          data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
        });
      }
    } else {
      push(route);
    }
  };

  const [deleteContactTag, { loading: deleteTagLoader }] = useMutation(
    REMOVE_TAG,
    {
      onCompleted() {},
      onError() {},
    }
  );

  const deleteTag = () => {
    const tagId = find(tagsList, (e) => e?.tag === tagName);
    if (tagId?.id) {
      deleteContactTag({
        variables: {
          id: parseInt(tagId?.id, 10),
          spaceId: parseInt(spaceId, 10),
        },
      })
        .then(() => {
          setOpenTagModal(false);
          if (tagName !== "source-mobile") {
            push(`/contacts/${currentSpaceId}/tag/all`);
            localStorage.setItem("deletedTagId", tagId?.id);
          }
        })
        .catch(() => {
          setOpenTagModal(false);
        });
    }
  };

  const handleOnClick = () => {
    if (removeContact) {
      doneRef?.current?.click();
    } else {
      editRef?.current?.click();
    }
  };

  return data?.length > 0 || (tag && tagName !== "all") ? (
    <div className="group-label-wrapper">
      <Row>
        <div className="group-label-sub-wrapper">
          <Col span={14} className="group-label-col">
            {tag ? (
              <TagsIcon className="folder-svg cms-svg-sm" />
            ) : (
              <Inquiry className="folder-svg cms-svg-sm" />
            )}
            <Text
              type="secondary"
              className="group-label-name cms-text-strong font-inter"
              title={tag ? tagName : capitalizeWord(data?.[0]?.name)}
            >
              {tag ? tagName : capitalizeWord(data?.[0]?.name)}
            </Text>
          </Col>
          {!tag ? (
            <Col
              span={hasGroupRight(userGroupPermission, ADD_CONTACT) ? 4 : 0}
              className="setting-col"
            >
              <HasRight action={ADD_CONTACT}>
                <IconButton
                  iconClass="font-inter cms-add-contact-button"
                  text="Add Contact"
                  handleClick={() =>
                    handleRouteChange(
                      `/contacts/${spaceId}/group/${groupId}/contact/new`,
                      true,
                      userSubscriptionInfo?.subscription?.planStatus,
                      userSubscriptionInfo?.subscription?.endDate
                    )
                  }
                />
              </HasRight>
            </Col>
          ) : (
            hasSpaceRight(userSpacePermission, DELETE_TAG) && (
              <Col span={10} className="cms-delete-tag-option cursor-pointer">
                <Dropdown
                  overlay={TagMenu(
                    setOpenTagModal,
                    tagModal,
                    deleteTag,
                    deleteTagLoader,
                    tagName
                  )}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <DeleteTagIcon className="cms-svg-regular" />
                </Dropdown>
              </Col>
            )
          )}
        </div>
      </Row>
      {(contactsCount > 0 || searchTerm?.length > 0) && (
        <div className="group-label-search-section">
          <div gutter={8} className="group-label-search-section-block">
            <div className="search-col">
              <SearchComponent
                setSearchTerm={setSearchTerm}
                groupId={groupId}
                searchTerm={searchTerm}
                spaceId={spaceId}
              />
            </div>
            {!hasGroupRight(userGroupPermission, ADD_EXISTING_CONTACT) ||
            tag ? (
              <div span={4} className="cms-contact-sort" />
            ) : (
              <div className="cms-contact-sort ml-8">
                <div
                  className={`${removeContact ? "cms-done-btn" : ""}
                  ${
                    !hasGroupRight(userGroupPermission, ADD_EXISTING_CONTACT)
                      ? "cms-done-btn-disable"
                      : ""
                  }
                  search-col-sorting search-col-sorting-single`}
                  onClick={handleOnClick}
                >
                  <div className="cms-svg-wrapper">
                    {removeContact ? (
                      <span
                        className="done-btn font-inter"
                        onClick={addMultipleContact}
                        ref={doneRef}
                      >
                        <ContactCloseIcon className="close-group-edit" />
                      </span>
                    ) : (
                      <span
                        ref={editRef}
                        onClick={() =>
                          handleRouteChange(
                            `/contacts/${spaceId}/group/${groupId}/add-contacts`,
                            true,
                            userSubscriptionInfo?.subscription?.planStatus,
                            userSubscriptionInfo?.subscription?.endDate
                          )
                        }
                      >
                        <EditIcon ref={editRef} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <Row className="group-label-wrapper">
      <div className="group-label-sub-wrapper">
        <Col
          span={
            // eslint-disable-next-line no-nested-ternary
            contactsCount === 0
              ? 14
              : isAll || width <= WINDOW_SIZE || (title && icon)
              ? 16
              : 24
          }
          className="group-label-col"
        >
          {!isAllContact && isAll && (
            <div className="cms-contact-actions-avatar cms-space-avatar-mRight mr-8">
              <AllGroupContact />
            </div>
          )}
          <Text
            type="secondary"
            className="group-label-name cms-text-strong font-inter"
          >
            {
              // eslint-disable-next-line no-nested-ternary
              pathname.includes("no-groups") ? (
                "No group Contacts"
              ) : // eslint-disable-next-line no-nested-ternary
              pathname.includes("deleted-contacts") ? (
                <>
                  <DeletedContactIcon className="cms-delete-icon-color" />
                  Deleted Contacts
                </>
              ) : title && icon ? (
                <>
                  {icon}
                  {title}
                </>
              ) : (
                "All Contacts"
              )
            }
          </Text>
        </Col>
        {contactsCount === 0 && width < WINDOW_SIZE && (
          <Col span={hasGroupRight(userGroupPermission, ADD_CONTACT) ? 8 : 0}>
            <HasRight action={ADD_CONTACT} type={SPACE}>
              <IconButton
                iconClass="font-inter cms-add-contact-button"
                text="Add Contact"
                handleClick={() =>
                  width <= WINDOW_SIZE
                    ? handleRouteChange(
                        `${ROUTES?.CONTACTS}/${spaceId}/all/contact/new`,
                        true,
                        userSubscriptionInfo?.subscription?.planStatus,
                        userSubscriptionInfo?.subscription?.endDate
                      )
                    : handleRouteChange(
                        `${ROUTES?.CONTACTS}/${spaceId}/${
                          tag ? "tag" : "group"
                        }/all/contact/new`,
                        true,
                        userSubscriptionInfo?.subscription?.planStatus,
                        userSubscriptionInfo?.subscription?.endDate
                      )
                }
              />
            </HasRight>
          </Col>
        )}
        {!isAllContact && isAll && width > WINDOW_SIZE && (
          <Col
            span={
              // eslint-disable-next-line no-nested-ternary
              isAll || width <= WINDOW_SIZE || (title && icon) ? 4 : 4
            }
            className="setting-col"
          >
            <HasRight action={ADD_CONTACT} type={SPACE}>
              <IconButton
                iconClass="font-inter cms-add-contact-button"
                text="Add Contact"
                handleClick={() =>
                  width <= WINDOW_SIZE
                    ? handleRouteChange(
                        `${ROUTES?.CONTACTS}/${spaceId}/all/contact/new`,
                        true,
                        userSubscriptionInfo?.subscription?.planStatus,
                        userSubscriptionInfo?.subscription?.endDate
                      )
                    : handleRouteChange(
                        `${ROUTES?.CONTACTS}/${spaceId}/${
                          tag ? "tag" : "group"
                        }/all/contact/new`,
                        true,
                        userSubscriptionInfo?.subscription?.planStatus,
                        userSubscriptionInfo?.subscription?.endDate
                      )
                }
              />
            </HasRight>
          </Col>
        )}
        {title && icon && (
          <Col span={4} className="setting-col">
            <IconButton
              iconClass="cms-icon-btn cms-icon-btn-size"
              shape="circle"
              handleClick={() =>
                handleRouteChange(
                  `/addOns/${spaceId}/reminders/settings`,
                  false
                )
              }
              icon={<Settings className="cms-common-svg setting-svg" />}
            />
          </Col>
        )}
        {width < WINDOW_SIZE && (
          <Col
            span={contactsCount === 0 ? 2 : 4}
            className="setting-col cms-mobile-logout"
          >
            <div
              onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm("Are you sure you want to logout?")) {
                  push(`${ROUTES.LOGOUT}`);
                }
              }}
            >
              <Logout className="cms-mobile-logout-btn" />
            </div>
          </Col>
        )}
      </div>

      {pathname.includes("reminders") &&
        ((title && icon && remindersCount > 0) || searchTerm?.length > 0) && (
          <div className="group-label-search-section">
            <Row gutter={8}>
              <Col span={24} className="search-col">
                <SearchComponent
                  setSearchTerm={setSearchTerm}
                  groupId={groupId}
                  searchTerm={searchTerm}
                  spaceId={spaceId}
                />
              </Col>
            </Row>
          </div>
        )}

      {!pathname.includes("reminders") &&
        (contactsCount > 0 || searchTerm?.length > 0) && (
          <>
            <div className="group-label-sub-wrapper">
              <Col
                span={
                  // eslint-disable-next-line no-nested-ternary
                  hasSpaceRight(
                    userSpacePermission || userSubscriptionInfo?.permission,
                    ADD_CONTACT
                  )
                    ? // eslint-disable-next-line no-nested-ternary
                      width <= MOBILE_TAB_SIZE
                      ? 16
                      : width <= WINDOW_SIZE
                      ? 20
                      : 24
                    : 24
                }
              >
                <div className="group-label-search-section">
                  <div className="group-label-search-section-block">
                    <div className="search-col">
                      <SearchComponent
                        setSearchTerm={setSearchTerm}
                        groupId={groupId}
                        spaceId={spaceId}
                        searchTerm={searchTerm}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {!isAllContact && isAll && width <= WINDOW_SIZE && (
                <Col
                  span={
                    // eslint-disable-next-line no-nested-ternary
                    width <= MOBILE_TAB_SIZE ? 8 : width <= WINDOW_SIZE ? 4 : 0
                  }
                >
                  <HasRight action={ADD_CONTACT} type={SPACE}>
                    <IconButton
                      iconClass="font-inter cms-add-contact-button add-contact-button-mobile"
                      text="Add Contact"
                      handleClick={() =>
                        width <= WINDOW_SIZE
                          ? handleRouteChange(
                              `${ROUTES?.CONTACTS}/${spaceId}/all/contact/new`,
                              true,
                              userSubscriptionInfo?.subscription?.planStatus,
                              userSubscriptionInfo?.subscription?.endDate
                            )
                          : handleRouteChange(
                              `${ROUTES?.CONTACTS}/${spaceId}/${
                                tag ? "tag" : "group"
                              }/all/contact/new`,
                              true,
                              userSubscriptionInfo?.subscription?.planStatus,
                              userSubscriptionInfo?.subscription?.endDate
                            )
                      }
                    />
                  </HasRight>
                </Col>
              )}
            </div>
          </>
        )}
      {
        //! Will remove later after testing
        /* {(contactsCount > 0 || searchTerm?.length > 0) && (
        <div className="group-label-search-section">
          {title && icon && remindersCount > 0 ? (
            <Row gutter={8}>
              <Col span={24} className="search-col">
                <SearchComponent
                  setSearchTerm={setSearchTerm}
                  groupId={groupId}
                  spaceId={spaceId}
                />
              </Col>
            </Row>
          ) : (
            <div className="group-label-search-section-block">
              <div className="search-col">
                <SearchComponent
                  setSearchTerm={setSearchTerm}
                  groupId={groupId}
                  spaceId={spaceId}
                />
              </div>
            </div>
          )}
        </div>
      )} */
      }
    </Row>
  );
};

export default ContactListHeader;
