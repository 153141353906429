import React from "react";
import { Email, Message, Phone, WhatsAppIconOutlined } from "../assets/svg";

/* ROUTERS  */
export const ROUTES = {
  MAIN: "/contacts",
  LOGIN: "/login",
  EMAIL_LOGIN: "/login/email",
  SIGNUP: "/signup",
  TELL_US_MORE: "/tell-us-more",
  AUTH_VERIFY: "/auth/new",
  VERIFY: "/verify",
  CREATE_PASSWORD: "/create-password",
  FORGOT_PASSWORD: "/forgot-password",
  SUCCESS: "/success",
  EMAIL_SENT: "/email-sent",
  LOGOUT: "/logout",
  ONBOARDING: "/onboarding",
  PROGRESS: "/progress",
  CONTACTS: "/contacts",
  CONTACTS_ALL_MOBILE: "/contacts/:spaceId/all",
  CONTACT_DETAIL_MOBILE: "/contacts/:spaceId/all/:contactId",
  CONTACT_EDIT_MOBILE: "/contacts/:spaceId/all/:contactId/edit",
  CONTACTS_ALL: "/contacts/all/:contactId",
  CONTACTS_ALL_EDIT: "/contacts/all/:contactId/edit",
  GROUP: "/contacts/:spaceId/group/:groupId",
  TAG: "/contacts/:spaceId/tag/:tagName",
  GROUP_CONTACT: "/contacts/:spaceId/*/contact/:contactId",
  TAG_CONTACT: "/contacts/:spaceId/*/contact/:contactId",
  SUMMARY: "/contacts/:spaceId/*/contact/:contactId/summary",
  NOTES: "/contacts/:spaceId/*/contact/:contactId/notes",
  TWITTER: "/contacts/:spaceId/*/contact/:contactId/twitter",
  FACEBOOK: "/contacts/:spaceId/*/contact/:contactId/facebook",
  INSTAGRAM: "/contacts/:spaceId/*/contact/:contactId/instagram",
  ADD_CONTACT_MOBILE: "/contacts/:spaceId/all/contact/new",
  ADD_CONTACT: "/contacts/:spaceId/*/contact/new",
  EDIT_CONTACT: "/contacts/:spaceId/*/contact/:contactId/edit",
  CLEANUP: "/clean-up",
  NOTIFICATIONS: "/notifications",
  GROUP_SETTING: "/contacts/:spaceId/group/:groupId/settings",
  NEW_GROUP: "/contacts/:spaceId/group/new",
  NEW_SPACE: "/contacts/space/new",
  MY_PROFILE: "/my-profile",
  CHANGE_PASSWORD: "/my-profile/change-password",
  SPACE_DETAIL: "/settings/:spaceId",
  SPACE_SETTING: "/settings/:spaceId/general",
  SPACE_MEMBERS: "/settings/:spaceId/members",
  CONTACT_SOURCES: "/settings/:spaceId/contact-sources",
  SPACE_IMPORT_CONTACTS: "/settings/:spaceId/import-contacts",
  SPACE_SUBSCRIPTION: "/settings/:spaceId/subscription",
  ADD_EXISTING_CONTACT: "/contacts/:spaceId/group/:groupId/add-contacts",
  ADD_CONTACT_SOURCES: "/settings/:spaceId/contact-sources/add",
  PLANS: "/space/:spaceId/plans",
  CLEANUP_DUPLICATES: "/clean-up/:spaceId/duplicates",
  CLEANUP_INCORRECT_DATA: "/clean-up/:spaceId/incorrect-data",
  CLEANUP_REMINDER_STAY_CONNECTED:
    "/clean-up/:spaceId/reminders/stay-connected",
  CLEANUP_REMINDER_BIRTHDAYS: "/clean-up/:spaceId/reminders/birthdays",
  CLEANUP_REMINDER_ANNIVERSARIES: "/clean-up/:spaceId/reminders/anniversaries",
  CLEANUP_MERGE_CONTACT:
    "/clean-up/:spaceId/duplicates/:duplicateId/merge-contact",
  ADDONS: "/addOns/:spaceId",
  ADDONS_REMINDERS: "/addOns/:spaceId/reminders",
  ADDONS_REMINDERS_ACTIVATE: "/addOns/:spaceId/reminders/activate",
  ADDONS_REMINDERS_SETTINGS: "/addOns/:spaceId/reminders/settings",
  ADDONS_ENRICHMENTS: "/addOns/:spaceId/enrichments",
  ADDONS_VISITORS_SIGNUP: "/addOns/:spaceId/visitor-sign-up",
  ADDONS_DIGITAL_TICKETS: "/addOns/:spaceId/digital-tickets",
  ADDONS_REMINDERS_CONTACT: "/addOns/:spaceId/reminders/:contactId",
  SETTINGS: "/settings",
  SUBSCRIPTION_CREATED: "/subscription-created",
  SUBSCRIPTION_UPDATED: "/subscription-updated",
  STRIPE_PAYMENT: "/stripe-payment-details",
  PUBLIC_CONTACT_LINK: "/contacts/public-link/:linkId",
};

/*  Modules */
export const MODULES = {
  MAIN: "Home",
  CONTACTS: "Contacts",
  ORGANIZATION: "Organizations",
  CLEANUP: "Clean up",
  NOTIFICATIONS: "Notifications",
  SETTINGS: "Settings",
  ADDONS: "AddOns",
};

/* Authentication */
export const TOKEN = "TOKEN";
export const USER = "USER";
export const ADMIN = "ADMIN";
export const USER_ID = "USER_ID";

/* Errors */

export const SERVER_ERROR = "SERVER_ERROR";

export const ROLES = {
  SUPER_ADMIN: "Super admin",
  ADMIN: "Admin",
};

/* Date and time */
export const defaultDateFormat = "MM-DD-YYYY";

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^[a-zA-Z0-9\d@$!%*#?&]{8,}$/,
  PHONE: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  GROUP_NAME: /^(?!.*  {1})[a-zA-Z\d(' -+_]{1,}/,
};

export const SETUPINTENT_RESPONSE = {
  SUCCEEDED: "succeeded",
  PROCESSING: "processing",
  PAYMENT_METHOD_REQUIRED: "requires_payment_method",
};

export const IMPORT_CONTACT_STATUS = {
  DONE: "DONE",
  COMPLETED: "COMPLETED",
};

export const TAB_SIZE = 992;
export const WINDOW_SIZE = 1024;
export const MOBILE_TAB_SIZE = 768;

export const ADDON_PARAMS = "addonParams";

export const MAIN_SITE_URL = "https://contactbook.app";

export const DOCUMENT_LINKS = {
  IMPORT_GUIDE: "https://doc.clickup.com/d/jjdr-8152/import-guide",
  PRIVACY_POLICY: "https://contactbook.app/privacy-policy",
  TERMS: "https://contactbook.app/terms",
};
export const LINK = {
  GOOGLE_API: "https://www.googleapis.com/auth/userinfo.email",
};
export const CONTACT_LIMIT = 20;
export const NOTES_LIMIT = 6;
export const EMPTY_ARRAY = [];
export const DATA_LIMIT = 100;
export const GROUP_LIMIT = 1000;

export const VISIBILITY = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

//! Group Permission
export const OWNER = "OWNER";
export const MANAGE = "MANAGE";
export const VIEW = "VIEW";
export const EDIT = "EDIT";
export const LIMITED = "LIMITED";
export const MANAGER = "Manager";

export const PERMISSIONS = [
  { label: "Admin", value: ADMIN },
  { label: "Manager", value: MANAGE },
  { label: "Can view", value: VIEW },
  { label: "Can edit", value: EDIT },
];

export const GOOGLE_PERMISSIONS = [{ label: "Admin", value: ADMIN }];

export const GROUP_PERMISSIONS = [
  { label: "Manager", value: MANAGE },
  { label: "Can view", value: VIEW },
  { label: "Can edit", value: EDIT },
];

export const CONTACT_CARD_OPTION = [
  { label: "Phone", value: "phone", icon: <Phone /> },
  { label: "Email", value: "email", icon: <Email height={14} width={14} /> },
  { label: "Text", value: "text", icon: <Message /> },
  { label: "WhatsApp", value: "whatsapp", icon: <WhatsAppIconOutlined /> },
];

// industries
export const INDUSTRIES = [
  "Real Estate/Properties",
  "Financial Services/Investment Services",
  "Educational Institutes",
  "Non Profits/Communities",
  "Business Services/Advertising & Marketing Services",
  "Engineering Services",
  "Employment/Career Consulting Services",
  "Software/Business & Productivity",
  "Telecom/Internet Service Providers",
  "Travel/Hotels & Accommodation",
  "Autos & Vehicles/Motor Vehicles/Motor Vehicles",
  "Ecommerce",
  "Gifts & Occasions/Gift Baskets",
  "Dating Services",
  "Home & Garden/Home Decor",
  "Other",
];

// sources
export const SOURCES = [
  "Google Search",
  "Google Marketplace",
  "LinkedIn",
  "Email",
  "Ads",
  "Social Media",
  "Other (Please specify)",
];

export const OTHER_SOURCE = "Other (Please specify)";
export const SOURCE_MOBILE = "source-mobile";
export const MOBILE_DEVICE_CONTACTS = "Mobile Device Contacts";

//! Attachment type
export const IMAGE = "IMAGE";
export const PDF = "PDF";
export const DOC = "DOC";
export const PPT = "PPT";
export const VIDEO = "VIDEO";
export const AUDIO = "AUDIO";

//! Group features
export const EDIT_GROUP_NAME = "editGroup";
export const DELETE_GROUP = "deleteGroup";
export const INVITE_USER = "inviteUser";
export const CHANGE_OWNER = "changeOwner";
export const CHANGE_PERMISSION = "changePermission";
export const REMOVE_USER = "removeUser";
export const EDIT_CONTACT = "editContact";
export const DELETE_CONTACT = "deleteContact";
export const ADD_NOTE = "addNote";
export const REMOVE_NOTE = "removeNote";
export const ADD_DOCUMENT = "addDocument";
export const EDIT_DOCUMENT = "editDocument";
export const DELETE_DOCUMENT = "deleteDocument";
export const ADD_CONTACT = "addContact";
export const ADD_EXISTING_CONTACT = "addExistingContact";
export const DELETE_TAG = "deleteTag";
export const SHARE_GROUP = "shareGroup";

//! Space feature
export const SHARE_SPACE = "shareSpace";
export const SPACE_SETTING = "spaceSetting";
export const SPACE_MEMBERS = "spaceMembers";
export const SPACE_CONTACT_SOURCE = "spaceContactSource";
export const SPACE_IMPORT_CONTACTS = "spaceImportContacts";
export const SPACE_BILLING = "spaceBilling";
export const CREATE_GROUP = "createGroup";
export const REINVITE_USER = "reInviteUser";
export const DELETE_SPACE = "deleteSpace";
export const EDIT_USER_SPACE = "editSpace";
export const ADD_SPACE_CONTACT_SOURCES = "addSpaceContactSource";
export const PRICING_PAGE = "pricingPage";

export const GROUP = "group";
export const SPACE = "space";
export const GROUP_SHARED = "Groups Shared";
export const INVITED = "invited";
export const REINVITE = "Re-invite";
export const CHANGE_OWNER_TEXT = "Change Owner";
export const NEW_CONTACT_SPACE = "New Contact Space";
export const CREATE_NEW_CONTACT = "Create New Contact";
export const IMPORT_CONTACTS = "Import Contacts";
export const ADD_EXISTING = "Add Existing Contact";
export const NO_MEMBERS_FOUND = "No members found";
export const FETCH_NOW = "Fetch Now";
export const ADD_NEW_SOURCE = "Add New Source";

export const REMOVE = "Remove";
export const CONFIRM = "Confirm";
export const ADD = "Add";
export const SAVE = "Save";
export const UPGRADE = "Upgrade";
export const LEAVE = "Leave";
export const SHARE = "Share";

export const LEAVE_GROUP = "Leave Group";
export const LEAVE_SPACE = "Leave Space";

export const SUCCESS = "Success";
export const ERROR = "Error";
export const WARNING = "Warning";

export const MONTHLY = "Monthly";
export const YEARLY = "Yearly";

export const UPDATE_PLAN = "Update Plan";

export const NOTIFICATION_CLEAR = "Notifications cleared successfully!";

//! Reminder
export const ACTIVATE_REMINDERS = "activateReminder";
export const DEACTIVATE_REMINDERS = "deActivateReminder";
export const BIRTHDAY = "Birthday";
export const ANNIVERSARY = "Anniversary";

export const WEB = "WEB";
//! Plan
export const MONTH = "month";
export const YEAR = "year";
export const LTD = "ltd";
export const LTD_PLAN_QUANTITY = 1;
export const RENEW_NOW = "Renew now";
export const PRO = "Pro";
export const BASIC = "Basic";
export const BUSINESS = "Business";
export const PRIVATE = "private";
export const TEAM = "Team";
export const PROFFESSIONAL = "Professional";
export const PUBLIC = "public";
export const INR = "inr";
export const USD = "usd";

export const ASSISTANT_DUPLICATE_CONTACT = "cleanUpDuplicateContact";

export const SYNC = "Sync";
export const GOOGLE = "GOOGLE";
export const DEFAULT = "DEFAULT";

export const NOTIFICATION_TYPE = {
  NEW_CONTACT: "NEW_CONTACT",
  NEW_NOTE: "NEW_NOTE",
  NEW_ATTACHMENT: "NEW_ATTACHMENT",
  CONTACT_REMOVED: "CONTACT_REMOVED",
  CONTACT_UPDATE: "CONTACT_UPDATE",
  NOTE_REMOVED: "NOTE_REMOVED",
  ATTACHMENT_REMOVED: "ATTACHMENT_REMOVED",
  NEW_GROUP: "NEW_GROUP",
  GROUP_UPDATE: "GROUP_UPDATE",
  GROUP_REMOVE: "GROUP_REMOVE",
  GROUP_SHARE: "GROUP_SHARE",
  GROUP_REVOKE: "GROUP_REVOKE",
  SPACE_SHARE: "SPACE_SHARE",
  SPACE_REVOKE: "SPACE_REVOKE",
  CONTACT_GROUP_ADDED: "CONTACT_GROUP_ADDED",
  CONTACT_GROUP_REMOVED: "CONTACT_GROUP_REMOVED",
  NEW_TAG: "NEW_TAG",
  TAG_REMOVED: "TAG_REMOVED",
  CONNECTION: "CONNECTION",
  BIRTHDAY: "BIRTHDAY",
  ANNIVERSARY: "ANNIVERSARY",
};
export const AVATAR_COLOR = [
  "#173F5F",
  "#4DA480",
  "#CB543F",
  "#7046A6",
  "#AB567F",
];

export const NO_ACCESS = "You do not have access to this page";

export const UPGRADE_PLAN_TITLE = "Plan not updated or expired!";

export const AMPLITUDE_EVENT_LOG = {
  NEW_USER_REGISTERED: "New User registered",
  CONTACT_CREATED: "Contact created",
  CONTACT_UPDATED: "Contact updated",
  CONTACT_DELETED: "Contact deleted",
  GROUP_CREATED: "Group created",
  GROUP_UPDATED: "Group updated",
  GROUP_DELETED: "Group deleted",
  SPACE_CREATED: "Space created",
  SPACE_UPDATED: "Space updated",
  SPACE_DELETED: "Space deleted",
  ATTACHMENT_ADDED: "Attachment added",
  ATTACHMENT_REMOVED: "Attachment removed",
  NOTE_ADDED: "Note added",
  NOTE_REMOVED: "Note removed",
  GROUP_SHARED: "Group shared",
  GROUP_REVOKED: "Group revoked",
  SPACE_REVOKED: "Space revoked",
  SPACE_SHARED: "Space shared",
  CONTACTS_IMPORTED: "Contacts imported",
  SOURCE_CONNECTED: "Source connected",
  OWNER_CHANGED: "Owner changed",
  MERGE_CONTACT: "Merge Contact",
  CONTACT_DELETED_PERMANENTLY: "Contact deleted permanently",
  CONTACT_RESTORE: "Contact restore",
  NEW_SUBSCRIPTION: "New subscription",
  UPDATE_SUBSCRIPTION: "Update subscription",
  CANCEL_SUBSCRIPTION: "Cancel subscription",
  CONTACT_REMINDER: "Contact reminder",
  GOOGLE_SPACE_SWITCHED: "Switched to google space",
  UPDATE_BUTTON_CLICKED: "Plan update button",
};
export const ACTIVITY_TYPE = {
  NEW_CONTACT: "NEW_CONTACT",
  NEW_NOTE: "NEW_NOTE",
  NEW_ATTACHMENT: "NEW_ATTACHMENT",
  NEW_TAG: "NEW_TAG",
  CONTACT_UPDATE: "CONTACT_UPDATE",
  ATTACHMENT_UPDATE: "ATTACHMENT_UPDATE",
  NOTE_REMOVED: "NOTE_REMOVED",
  ATTACHMENT_REMOVED: "ATTACHMENT_REMOVED",
  TAG_REMOVED: "TAG_REMOVED",
  NEW_GROUP: "NEW_GROUP",
  GROUP_REMOVE: "GROUP_REMOVE",
};

export const SUBSCRIPTION_FEATURE = [
  { title: "Contacts", subTitle: "Management" },
  { title: "Group Sharing", subTitle: "with users" },
  { title: "Space sharing" },
  { title: "Sync sources", subTitle: "Google, Microsoft & Apple" },
  { title: "Groups & Tags" },
  { title: "Notes", subTitle: "Public & Private" },
  { title: "Documents" },
  { title: "Contact activities" },
  { title: "Permission Management" },
  { title: "Reminders" },
  { title: "Mobile Apps" },
  { title: "Support" },
  { title: "Installation and setup" },
  { title: "Contact Backup" },
];

export const GOOGLE_SUBSCRIPTION_FEATURE = [
  { title: "Contacts", subTitle: "Management" },
  { title: "Group Sharing", subTitle: "with users" },
  { title: "Space admin" },
  { title: "Groups & Tags" },
  { title: "Notes" },
  { title: "Documents" },
  { title: "Contact activities" },
  { title: "Permission Management" },
  { title: "Reminders" },
  { title: "Auto sync" },
  { title: "Mobile Apps" },
  { title: "Support" },
  { title: "Installation and setup" },
  { title: "Contact Backup" },
];

export const PLAN_STATUS = {
  ACTIVE: "ACTIVE",
  TRIAL: "TRIAL",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  UNPAID: "UNPAID",
  PAST_DUE: "PAST_DUE",
};
export const AUTO_TAGS = [
  "no-email",
  "no-address",
  "no-phone",
  "no-company",
  "no-last-name",
  "no-first-name",
  "no-country-code",
  "source-microsoft",
  "source-google",
  "source-csv",
  "source-vcf",
  "source-mobile",
];
export const IMAGE_EXTENSION = ["JPG", "jpg", "PNG", "png", "JPEG", "jpeg"];
export const VIDEO_EXTENSION = ["mov", "wav", "mp4", "MOV", "WAV", "MP4"];
export const AUDIO_EXTENSION = ["mp3", "avi", "MP3", "AVI"];

export const ATTACHMENT_EXTENSION = [
  "JPG",
  "jpg",
  "PNG",
  "png",
  "JPEG",
  "jpeg",
  "pdf",
  "DOC",
  "doc",
  "DOCX",
  "docx",
  "mp3",
  "MP3",
  "mov",
  "MOV",
  "avi",
  "AVI",
  "mp4",
  "MP4",
  "wav",
  "WAV",
];

//! Clean up module constants
export const CLEANUP_CONSTANTS = {
  DUPLICATES: "duplicates",
  INCORRECT_DATA: "incorrect-data",
  REMINDERS: "reminders",
  STAY_CONNECTED: "stay-connected",
  BIRTHDAYS: "birthdays",
  ANNIVERSARIES: "anniversaries",
};

export const REMINDER_MENU = [
  { label: "None", value: "NONE" },
  { label: "Weekly", value: "WEEK" },
  { label: "Every 2 weeks", value: "EVERY_2_WEEK" },
  { label: "Monthly", value: "MONTH" },
  { label: "Every 2 months", value: "EVERY_2_MONTH" },
  { label: "Quarterly", value: "QUARTER" },
  { label: "Every 6 months", value: "EVERY_6_MONTH" },
  { label: "Yearly", value: "YEAR" },
];

export const REMINDER_TYPES = {
  CONNECTION: "CONNECTION",
  BIRTHDAY: "BIRTHDAY",
  ANNIVERSARY: "ANNIVERSARY",
};

export const REMINDER_STATUS = {
  UPCOMING: "UPCOMING",
  PAST: "PAST",
  TODAY: "TODAY",
};

export const REMINDER_MENU_LISTING = {
  WEEK: "Weekly",
  MONTH: "Monthly",
  YEAR: "Yearly",
  EVERY_2_WEEK: "Every 2 weeks",
  EVERY_2_MONTH: "Every 2 months",
  EVERY_6_MONTH: "Every 6 months",
  QUARTER: "Quarterly",
};

export const EMPTY_STATE_REMINDER_TEXT =
  "Set up check-in reminders on your contacts. You will get notifications when it's time to connect with them. Check upcoming and previous week reminders from here.";

export const SHARE_GOOGLE_CONTACTS_TEXT =
  "Easily share your Google labels with other Google users. They will receive contacts within those labels in their Google Contacts. Follow the steps as shown in the video above.";

export const SELECT_GROUPS_TEXT = "Select groups you would like to share.";
export const GROUP_SYNC_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

export const DEACTIVATE_REMINDER_TEXT =
  "Are you sure you want to deactivate reminders? This will remove existing reminders you may have set on your contacts. You will stop receiving notifications.";

export const SYNC_LOG_TYPES = {
  SPACE_SYNC_END: "SPACE_SYNC_END",
  SPACE_SYNC_START: "SPACE_SYNC_START",
  GROUP_SHARE_END: "GROUP_SHARE_END",
};

export const APP_STORE_URL =
  "https://apps.apple.com/ph/app/contactbook-contact-manager/id1614251791";
export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.logicwind.contactbook";
export const IMPORT_SAMPLE_FILE_URL =
  "https://contact-management-system-prod.s3.amazonaws.com/sample-google-csv/CSV_import_template.csv";
export const SHARE_GOOGLE_CONTACTS_URL =
  "https://www.contactbook.app/help/share-google-contacts";
// source type for contact delete
export const SOURCE_TYPE = {
  ALL_CONTACTS: "ALL_CONTACTS",
  NO_GROUP_CONTACTS: "NO_GROUP_CONTACTS",
  DELETED_CONTACTS: "DELETED_CONTACTS",
  GROUP_CONTACTS: "GROUP_CONTACTS",
  TAG_CONTACTS: "TAG_CONTACTS",
};

export const FILE_UPLOAD_ERROR_MESSAGE =
  "Error uploading file, please try again later!";

export const GOOGLE_SPACE_ONBOARDING = "GOOGLE_SPACE_ONBOARDING";

export const PLAN_NAMES = {
  BASIC: "Basic",
  PRO: "Pro",
  BUSINESS: "Business",
  PROFESSIONAL: "Professional",
};
