import React, { memo, useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import find from "lodash/find";
import uniqBy from "lodash/uniqBy";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import _isInteger from "lodash/isInteger";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import { nth } from "lodash";

//! Ant Imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Skeleton from "antd/lib/skeleton";
import Title from "antd/lib/typography/Title";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Tooltip } from "antd";

//! User Files
import {
  SET_ACTIVE_SWITCH,
  SET_CURRENT_SIDE_TAB,
  SET_USER_SUBSCRIPTION_INFO,
  SET_USER_SPACE_PERMISSION,
} from "../../../../../common/actionTypes";
import { AppContext } from "../../../../../AppContext";
import GroupList from "../components/GroupList";
import TagsList from "../components/TagsList";
import {
  AddContact,
  AllGroupContact,
  ChevronRight,
  Folder,
  GoogleLogo,
  SharedSpaceIcon,
} from "../../../../../assets/svg";
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import {
  createAmplitudeEvent,
  generateRandomColor,
  isMobileBrowser,
  isPlanActive,
} from "../../../../../common/utils";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import GroupCreateUpdateModal from "../../../../../common/components/GroupCreateUpdateModal/GroupCreateUpdateModal";
import {
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  CREATE_GROUP as GROUP_CREATE,
  GOOGLE,
  OWNER,
  PLAN_STATUS,
  SELECT_GROUPS_TEXT,
  SHARE_GROUP,
  SPACE,
  UPGRADE_PLAN_TITLE,
  GOOGLE_SPACE_ONBOARDING,
} from "../../../../../common/constants";
import * as ActionTypes from "../../../../../common/actionTypes";
import { HasRight } from "../../../../../common/HasRight";
import CountTag from "../../../../../common/components/CountTag";
import client from "../../../../../apollo";
import NoGroupTagFound from "../../../../../common/components/NoGroupTagFound/NoGroupTagFound";
import OnBoardingModal from "../../../../onboard/components/OnBoardingModal";
import SpaceShareModal from "../components/SpaceShareModal";
import { GET_GOOGLE_AUTH_URL } from "../../../../auth/graphql/Queries";

//! Graphql files
import { CREATE_GROUP } from "../graphql/Mutations";
import {
  COMPLETE_ONBOARDING,
  VERIFY_GOOGLE_CODE,
  VERIFY_MICROSOFT_CODE,
} from "../../../../onboard/graphql/Mutations";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import { GET_GROUP_LIST_V2 } from "../../../graphql/queries/getGroupListV2";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";

const GroupsNav = () => {
  const { pathname, search } = useLocation();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [activeBtn, setActiveBtn] = useState("group");
  const [currentSpace, setCurrentSpace] = useState();
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [boardingModal, setBoardingModal] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [groupCheckList, setGroupCheckList] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [offset, setOffset] = useState(0);
  const [runTour, setRunTour] = useState(false);
  const { push } = useHistory();
  const history = useHistory();
  const {
    state: { activeSwitch, userSubscriptionInfo, currentUser },
    dispatch,
  } = useContext(AppContext);
  const currentSpaceId =
    localStorage.getItem("currentSpace") ||
    pathname?.split("/")[2] ||
    currentUser?.spaceId;
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const urlProvider = params.get("provider") ?? location?.provider;
  const paramsError = params.get("error");
  const migrate = params.get("migrate");
  const [completeOnBoarding] = useMutation(COMPLETE_ONBOARDING);
  const currentStep = parseInt(localStorage.getItem("currentStep"), 10);
  const { width } = useWindowSize();
  const isGroup = nth(location?.pathname?.split("/"), 3) === "group";
  const currentGroupId = pathname?.split("/")[4];
  const [showTooltip, setShowToolTip] = useState(false);
  const isSpacePlanActive = isPlanActive(
    userSubscriptionInfo?.subscription?.planStatus,
    userSubscriptionInfo?.subscription?.endDate
  );

  const isFreeTrial =
    userSubscriptionInfo?.subscription?.planStatus === PLAN_STATUS.TRIAL;
  const deletedGroupId = location?.deletedGroupId;

  const [googleAuthUrl] = useLazyQuery(GET_GOOGLE_AUTH_URL, {
    onCompleted(data) {
      window.location.href = data?.getGoogleAuthUrl?.authorizationUri;
    },
    onError() {},
  });

  useEffect(() => {
    if (history?.action === "POP") {
      localStorage.removeItem(GOOGLE_SPACE_ONBOARDING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isOnBoardingDone = localStorage.getItem("contactSource");
    const spaceId =
      localStorage.getItem("currentSpace") || currentUser?.spaceId;
    if (isOnBoardingDone && code && urlProvider) {
      push(`/settings/${spaceId}/contact-sources`);
      localStorage.setItem("sourceCode", code);
      localStorage.setItem("sourceProvider", urlProvider);
    } else if (code && urlProvider === "GOOGLE" && migrate !== "true") {
      client
        .mutate({
          mutation: VERIFY_GOOGLE_CODE,
          variables: { code, spaceId: parseInt(currentSpaceId, 10) },
        })
        .then((res) => {
          dispatch({
            type: ActionTypes?.SET_CONNECT_SOURCE_PARAMS,
            data: {
              provider: urlProvider,
              code: code,
              jobId: res?.data?.verifyGoogleCode?.jobId,
              isVerified: true,
              spaceId: spaceId,
            },
          });
          dispatch({
            type: ActionTypes?.SET_IMPORT_CONTACT_STATUS,
            data: "PENDING",
          });
          localStorage.setItem("currentStep", currentStep + 1);
          completeOnBoarding().then().catch();
          push(isMobileBrowser(`/contacts/${spaceId}/group/all`, width));
        })
        .catch(() => {
          push(isMobileBrowser(`/contacts/${spaceId}/group/all`, width));
        });
    } else if (code && urlProvider === "MICROSOFT") {
      client
        .mutate({
          mutation: VERIFY_MICROSOFT_CODE,
          variables: { code, spaceId: parseInt(spaceId, 10) },
        })
        .then((res) => {
          dispatch({
            type: ActionTypes?.SET_CONNECT_SOURCE_PARAMS,
            data: {
              provider: urlProvider,
              code: code,
              jobId: res?.data?.verifyMicrosoftCode?.jobId,
              isVerified: true,
              spaceId: spaceId,
            },
          });
          dispatch({
            type: ActionTypes?.SET_IMPORT_CONTACT_STATUS,
            data: "PENDING",
          });
          completeOnBoarding().then().catch();
          localStorage.setItem("currentStep", currentStep + 1);
          push(isMobileBrowser(`/contacts/${spaceId}/group/all`, width));
        })
        .catch(() => {
          push(isMobileBrowser(`/contacts/${spaceId}/group/all`, width));
        });
    } else if (paramsError === "access_denied") {
      localStorage.setItem("currentStep", 1);
      if (isOnBoardingDone) {
        push(`/settings/${spaceId}/contact-sources`);
      } else {
        push(isMobileBrowser(`/contacts/${spaceId}/group/all`, width));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const { data: spaceData } = useQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(currentSpaceId, 10),
    },
    onCompleted({ spaceV2 }) {
      setCurrentSpace(spaceV2);
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceV2,
      });
      dispatch({
        type: SET_USER_SPACE_PERMISSION,
        data: spaceV2?.permission,
      });
    },
    onError() {
      localStorage.setItem("currentSpace", currentUser?.spaceId);
      push(`/contacts/${currentUser?.spaceId}/group/all`);
    },
  });

  const [
    getGroupList,
    { data: groupList, loading: groupListLoader },
  ] = useLazyQuery(GET_GROUP_LIST_V2, {
    fetchPolicy: "network-only",
    variables: {
      offset: offset,
      limit: CONTACT_LIMIT,
      spaceId: parseInt(currentSpaceId, 10),
    },
    onError() {},
  });

  const [createGroup, { loading: groupLoader }] = useMutation(CREATE_GROUP, {
    onError() {},
  });

  const [getGroupInfo, { data: groupData }] = useLazyQuery(GET_GROUP_INFO_V2, {
    fetchPolicy: "network-only",
    onError(error) {
      if (
        error?.message ===
        "Cannot destructure property 'permissions' of '(intermediate value)' as it is null."
      ) {
        push(`/contacts/${currentSpaceId}/group/all`);
      }
    },
  });

  useEffect(() => {
    if (currentSpaceId > 0) {
      getGroupList();
      setGroups([]);
      dispatch({
        type: ActionTypes?.SET_SPACE_GROUPS,
        data: [],
      });
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpaceId]);

  useEffect(() => {
    if (groupList) {
      setGroups(
        sortBy(
          uniqBy([...groupList?.groupListV2?.groups, ...groups], "id"),
          "name"
        )
      );
      dispatch({
        type: ActionTypes?.SET_SPACE_GROUPS,
        data: sortBy(
          uniqBy([...groupList?.groupListV2?.groups, ...groups], "id"),
          "name"
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupList]);

  useEffect(() => {
    if (deletedGroupId) {
      const updateGroups = filter(
        groups,
        (val) => val?.id !== parseInt(deletedGroupId, 10)
      );
      setGroups(updateGroups);
      dispatch({
        type: ActionTypes?.SET_SPACE_GROUPS,
        data: updateGroups,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedGroupId]);

  useEffect(() => {
    const memberArr = [];
    if (spaceData?.spaceV2) {
      setCurrentSpace(spaceData?.spaceV2);
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceData?.spaceV2,
      });
    }
    if (spaceData?.space?.members) {
      memberArr.push(
        find(spaceData?.space?.members, (e) => e?.permissions === OWNER)
      );
    }
    setMembers(memberArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceData]);

  useEffect(() => {
    if (
      isGroup &&
      currentGroupId !== "all" &&
      currentGroupId !== "new" &&
      currentGroupId !== null &&
      _isInteger(parseInt(currentGroupId, 10))
    ) {
      getGroupInfo({
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(currentGroupId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupId, groupList]);

  useEffect(() => {
    if (groupData?.groupInfoV2) {
      const isGroupExists = find(groups, (e) => {
        return (
          parseInt(e?.id, 10) === parseInt(groupData?.groupInfoV2?.id, 10) ||
          e?.name === groupData?.groupInfoV2?.name
        );
      });
      const tempGroups = cloneDeep(groups);
      if (!isGroupExists) {
        tempGroups.splice(0, 0, groupData?.groupInfoV2);
        setGroups(uniqBy(tempGroups, "id"));
        dispatch({
          type: ActionTypes?.SET_SPACE_GROUPS,
          data: uniqBy(tempGroups, "id"),
        });
      } else {
        const index = findIndex(groups, (e) => e?.id === isGroupExists?.id);
        tempGroups[index] = groupData?.groupInfoV2;
        setGroups(uniqBy(tempGroups, "id"));
        dispatch({
          type: ActionTypes?.SET_SPACE_GROUPS,
          data: uniqBy(tempGroups, "id"),
        });
      }
      //! keeping this for future reference
      // else if (!isGroupExists?.groupOwner) {
      //   tempGroups.unshift(groupData?.groupInfoV2);
      //   setGroups(uniqBy(tempGroups, "id"));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  const handleChangeRoute = (route) => {
    dispatch({ type: SET_CURRENT_SIDE_TAB, data: "" });
    push(route);
  };

  const setActiveGroupTag = (value) => {
    setActiveBtn(value);
    dispatch({
      type: SET_ACTIVE_SWITCH,
      data: value,
    });
  };

  const handleGroupTagRoute = (route, value) => {
    push(route);
    setActiveGroupTag(value);
  };

  useEffect(() => {
    if (activeSwitch === "tag" && pathname.includes("tag")) {
      setActiveBtn("tag");
    } else {
      setActiveBtn("group");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSwitch, pathname]);

  const handleGroupModal = () => {
    if (
      !isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      dispatch({
        type: ActionTypes?.SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    } else {
      setVisible(!visible);
    }
  };

  const handleCreateGroup = (e, form) => {
    const { name, visibility } = e;
    createGroup({
      variables: {
        name: name?.trim(),
        type: visibility ? "PRIVATE" : "PUBLIC",
        spaceId: parseInt(currentSpaceId, 10),
      },
    })
      .then((res) => {
        if (res?.data !== undefined) {
          form.resetFields();
          const groupName = res?.data?.createGroup?.data?.name;
          const tempGroups = cloneDeep(groups);
          tempGroups.splice(0, 0, res?.data?.createGroup?.data);
          setGroups(uniqBy(tempGroups, "id"));
          dispatch({
            type: ActionTypes?.SET_SPACE_GROUPS,
            data: uniqBy(tempGroups, "id"),
          });

          if (groupName) {
            dispatch({
              type: ActionTypes?.SET_CURRENT_SIDE_TAB,
              data: groupName,
            });
          }
          const eventProperties = {
            "Group Id": res?.data?.editGroup?.data?.id,
            "Group Name": res?.data?.editGroup?.data?.name,
            "Space Id": currentSpaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.GROUP_CREATED,
            eventProperties
          );
          setVisible(false);
          push(
            `/contacts/${currentSpaceId}/group/${res?.data?.createGroup?.data?.id}/settings`
          );
        }
      })
      .catch(() => {});
  };

  const handleSpaceContactClick = (spaceId) => {
    handleChangeRoute(`/contacts/${spaceId}/${activeSwitch}/all`);
  };

  const closeOnBoardingModal = () => {
    setBoardingModal(false);
    completeOnBoarding().then().catch();
    localStorage.removeItem("GoogleLoader");
    localStorage.removeItem("GoogleSwitchComplete");
    localStorage.removeItem(GOOGLE_SPACE_ONBOARDING);
    if (currentSpace?.type === GOOGLE) {
      window.location.reload();
    }
  };

  const closeOnBoardingModalWithGoogleSwitch = () => {
    setBoardingModal(false);
    localStorage.setItem(GOOGLE_SPACE_ONBOARDING, true);

    googleAuthUrl({
      variables: {
        screen: "contacts",
      },
    });
  };

  useEffect(() => {
    if (!currentUser?.hasLoggedInBefore && currentStep !== 3) {
      setBoardingModal(true);
    }
  }, [currentUser, currentStep]);

  const handleCheckAll = (e) => {
    const ids = [];
    groups?.map((key) => ids.push(parseInt(key?.id, 10)));
    setCheckAll(e?.target?.checked);
    setGroupCheckList(
      // eslint-disable-next-line no-nested-ternary
      e?.target?.checked
        ? groupCheckList?.length > 0
          ? groupCheckList
          : ids
        : []
    );
    dispatch({
      type: ActionTypes?.SET_CURRENT_SELECTED_LABELS,
      // eslint-disable-next-line no-nested-ternary
      data: e?.target?.checked
        ? groupCheckList?.length > 0
          ? groupCheckList
          : ids
        : [],
    });
  };

  //! Will remove after testing
  // useEffect(() => {
  //   if (groupCheckList?.length === groups?.length && groups?.length !== 0) {
  //     setCheckAll(true);
  //   } else {
  //     setCheckAll(false);
  //   }
  // }, [groupCheckList, groups]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      !groupListLoader &&
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      groups?.length < groupList?.groupListV2?.count
    ) {
      setOffset(offset + CONTACT_LIMIT);
    }
  };

  const handleShareClick = () => {
    if (groupCheckList?.length > 0 || checkAll) {
      setOpenShareModal(!openShareModal);
      localStorage.setItem("runTour", false);
      setRunTour(false);
      dispatch({
        type: ActionTypes?.SET_RUN_TOUR,
        data: false,
      });
    } else {
      setShowToolTip(true);
    }
  };

  return (
    <>
      <div className="group-list h-inherit">
        <Row className="group-list-row">
          <Col className="group-list-col group-tags-tab group-bottom-line">
            {currentSpace?.name ? (
              <div className="cms-current-space-title">
                <div
                  className="cms-current-space-title-block"
                  title={currentSpace?.name}
                >
                  <div className="cms-current-space-title-block-left-section">
                    <span>
                      <UserAvatar
                        className="cms-current-space-title-block-avatar mr-8"
                        name={currentSpace?.name}
                        photo={currentSpace?.photo}
                        size={24}
                        color={generateRandomColor(currentSpace?.id)}
                      />
                    </span>
                    {userSubscriptionInfo?.type === GOOGLE && (
                      <span className="cms-google-space-logo">
                        <GoogleLogo />
                      </span>
                    )}
                    <span className="font-inter username cms-current-space-title-block-text cms-font-ellipsis">
                      {currentSpace?.name}
                    </span>
                  </div>
                  {currentSpace?.ownerId !== parseInt(currentUser?.id, 10) && (
                    <SharedSpaceIcon className="mr-2" title="Shared space" />
                  )}
                </div>
              </div>
            ) : (
              <div className="cms-current-space-title">
                <div className="cms-current-space-title-block">
                  <Skeleton
                    avatar
                    active
                    title={false}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col
            className={`${
              currentSpaceId === currentSpace?.id && pathname.includes("all")
                ? "active-bg"
                : ""
            }
           group-list-col all-contacts-tab group-bottom-line cms-all-cnt-block`}
          >
            <Title
              level={5}
              className={`${
                currentSpaceId === currentSpace?.id && pathname.includes("all")
                  ? "cms-title-all-selected"
                  : ""
              } group-list-heading cms-title-all`}
              onClick={() => handleSpaceContactClick(currentSpace?.id)}
            >
              <div className="cms-title-all-left">
                <AllGroupContact className="cms-all" />
                <span>All Contacts</span>
                <CountTag count={currentSpace?.contactsCount || 0} />
              </div>
              <ChevronRight className="cms-all-contact-svg" />
            </Title>
          </Col>
          <Col className="group-list-col group-tags-tab group-bottom-line">
            <div className="button-switch">
              <div
                className="button-inside-div"
                onClick={() =>
                  handleGroupTagRoute(
                    `/contacts/${currentSpaceId}/group/all`,
                    "group"
                  )
                }
              >
                <Button
                  className={activeBtn === "group" && "active"}
                  type="text"
                >
                  Groups
                </Button>
              </div>
              <div
                className="button-inside-div"
                onClick={() =>
                  handleGroupTagRoute(
                    `/contacts/${currentSpaceId}/tag/all`,
                    "tag"
                  )
                }
              >
                <Button className={activeBtn === "tag" && "active"} type="text">
                  Tags
                </Button>
              </div>
            </div>
          </Col>
          <div className="cms-group-share-joyride">
            {groups?.length > 0 && pathname.includes("group") && (
              <HasRight action={SHARE_GROUP} type="space">
                <Col className="group-list-col group-tags-tab group-bottom-line d-flex-center cms-select-all-wrapper">
                  <div className="d-flex-center cms-select-all-wrapper-checkbox">
                    <span className="cms-select-all-label-text d-flex-center">
                      Select All
                    </span>
                    <span className="mr-8">
                      <Checkbox
                        className="cms-select-all-label d-flex-center"
                        onClick={(e) => handleCheckAll(e)}
                        checked={checkAll}
                      />
                    </span>
                  </div>

                  <Tooltip
                    placement="bottomLeft"
                    title={SELECT_GROUPS_TEXT}
                    visible={showTooltip}
                    color="#101326"
                    trigger="click"
                    onVisibleChange={() => setShowToolTip(false)}
                    overlayClassName="custom-tooltip"
                  >
                    <IconButton
                      iconClass="cms-share-button cms-label-share-icon"
                      className="cms-label-share-icon"
                      type="primary"
                      text="Share"
                      handleClick={handleShareClick}
                    />
                  </Tooltip>
                </Col>
              </HasRight>
            )}
            {
              // eslint-disable-next-line no-nested-ternary
              activeBtn === "group" ? (
                groups?.length === 0 &&
                (userSubscriptionInfo?.type === GOOGLE ||
                  currentSpace?.deletedContactsCount === 0) &&
                currentSpace?.noGroupContactsCount === 0 ? (
                  <NoGroupTagFound
                    text="No groups found"
                    icon={<Folder />}
                    isSpacePlanActive={isSpacePlanActive}
                    isFreeTrial={isFreeTrial}
                  />
                ) : (
                  <GroupList
                    space={currentSpace}
                    groupListLoader={groupListLoader}
                    isSpacePlanActive={isSpacePlanActive}
                    isFreeTrial={isFreeTrial}
                    checkAll={checkAll}
                    setGroupCheckList={setGroupCheckList}
                    groupCheckList={groupCheckList}
                    groups={groups}
                    handleInfiniteScroll={handleInfiniteScroll}
                    offset={offset}
                    setRunTour={setRunTour}
                    runTour={runTour}
                    setCheckAll={setCheckAll}
                  />
                )
              ) : (
                <TagsList space={currentSpace} />
              )
            }
          </div>
        </Row>
        {activeBtn === "group" && (
          <HasRight action={GROUP_CREATE} type={SPACE}>
            <div className="cms-create-group-block" title="Create New Group">
              <IconButton
                iconClass="cms-current-space-title-block cms-create-group-hover"
                text={
                  <span className="font-inter cms-current-space-title-block-text">
                    Create New Group
                  </span>
                }
                icon={
                  <span className="cms-current-space-title-block-icon mr-8">
                    <AddContact />
                  </span>
                }
                handleClick={handleGroupModal}
              />
            </div>
          </HasRight>
        )}
        <GroupCreateUpdateModal
          visible={visible}
          onCancel={handleGroupModal}
          title="Create Group"
          centered
          footer={null}
          buttonText="Create"
          onFinish={handleCreateGroup}
          loading={groupLoader}
          setIsFormUpdated={setIsFormUpdated}
          isFormUpdated={isFormUpdated}
        />
      </div>
      <OnBoardingModal
        visible={boardingModal}
        closeOnBoardingModal={closeOnBoardingModal}
        closeOnBoardingModalWithGoogleSwitch={
          closeOnBoardingModalWithGoogleSwitch
        }
      />
      <SpaceShareModal
        setOpenShareModal={setOpenShareModal}
        openShareModal={openShareModal}
        members={members}
        spaceId={currentSpaceId}
        groupCheckList={groupCheckList}
        setGroupCheckList={setGroupCheckList}
        checkAll={checkAll}
        setCheckAll={setCheckAll}
      />
    </>
  );
};

export default memo(GroupsNav);
